import moment from 'moment';
import React, { useEffect, useState, useCallback, memo, useRef, useMemo } from 'react';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import useRequest from '../../../hooks/useRequest';
import { useMst } from '../../../models';
import Badge from '../../../components/bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import showNotification from '../../../components/extras/showNotification';
import { Base64 } from 'js-base64';

const UserApprovalNeedCard = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const navigate = useNavigate();
	const { user, company } = useMst();
	const { responseData, loading, request } = useRequest([]);
	const ref = useRef();
	const [selectedDate, setSelectedDate] = useState({
		startDate: moment().startOf('year').format('YYYY-MM-DD'),
		endDate: moment().endOf('year').format('YYYY-MM-DD'),
	});
	const columns = useMemo(() => [{ name: '기안자' }, { name: '직급' }, { name: '유형' }, { name: '기안일' }], []);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	useEffect(() => {
		request(
			CompanyService.requestList,
			[{ companyId: company.get.id, userId: user.me.id }],
			(res) => {
				let filteredData = { list: [] };
				if ('data' in res) {
					filteredData = {
						...res.data,
						list: res.data.list.filter((r) => r.state === 'REQUEST'),
					};
				}
				return { data: filteredData };
			},
			(error) => {
				showNotification(
					t('요청받은 결재 현황'),
					t('요청받은 결재 현황을 불러오는 도중에 문제가 발생했습니다.'),
					'danger'
				);
			},
			{
				key: ['createdAt'],
				deepDive: ['list'],
				direction: 'descending',
				type: 'date',
			}
		);
	}, [user, company, request, t]);

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const rowData = responseData?.list?.[rowIndex - 1];

			switch (columnIndex) {
				case 0:
					data = (
						<div>
							<Avatar
								className='me-1'
								round
								textSizeRatio={0.1}
								size={30}
								name={rowData?.user?.name || ''}
								src={rowData?.user?.profile || null}
							/>
							{rowData.user?.name}
						</div>
					);
					break;
				case 1:
					data = rowData?.rank?.name || '-';
					break;
				case 2:
					data = rowData?.restday?.type || '-';
					break;
				case 3:
					data = rowData?.createdAt ? moment(rowData.createdAt).format('L') : '-';
					break;
			}

			return data;
		},
		[responseData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(columns[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer ${
							columnIndex === 0 ? 'justify-content-start' : 'justify-content-center'
						} ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
						onClick={() => {
							const cryptoUrl = Base64.encode(`${company.get.id}_${responseData.list[rowIndex - 1].id}`);
							navigate(`/e-approval/document/${cryptoUrl}`);							
						}}>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, columns, responseData, navigate, renderText, t, company.get.id]
	);

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle className='cursor-pointer' onClick={() => navigate(usersMenu.RequestedApproval.path)}>
						{t('요청받은 결재 현황')}
					</CardTitle>
				</CardLabel>
				<CardActions>
					<Badge color='light' className='text-wrap'>
						<span className='text-dark'>{t('총 상신 수')} : </span>
						<span className='fw-bold text-info fs-6'>{responseData?.list?.length || 0}</span>
					</Badge>
				</CardActions>
			</CardHeader>
			<CardBody
				className='dashboard-height overflow-auto'
				ref={ref}
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{loading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>데이터를 불러오고 있습니다.</p>
					</div>
				) : responseData?.list?.length <= 0 ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<Icon size={'9x'} color={'success'} icon={'CheckCircle'} />
						<p>요청받은 결재가 없습니다.</p>
					</div>
				) : (
					<div className='w-100 h-100'>
						<AutoSizer>
							{({ width, height }) => {
								cellCache.clearAll();
								return (
									<MultiGrid
										classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
										classNameTopRightGrid='border-light border-bottom fw-bold'
										classNameBottomLeftGrid='border-light border-end'
										cellRenderer={cellRenderer}
										columnCount={columns.length}
										fixedColumnCount={0}
										fixedRowCount={1}
										rowCount={responseData?.list?.length + 1}
										columnWidth={(width - 12) / columns.length}
										rowHeight={cellCache.rowHeight}
										height={height}
										width={width}
									/>
								);
							}}
						</AutoSizer>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default memo(UserApprovalNeedCard);
