import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import useRequest from '../../../hooks/useRequest';
import { useMst } from '../../../models';
import '../../Board/css/_custom.scss';
import { useTranslation } from 'react-i18next';
import Badge from '../../../components/bootstrap/Badge';
import showNotification from '../../../components/extras/showNotification';

const NoticeBoard = () => {
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef();
	const { company } = useMst();
	const { responseData, loading, request } = useRequest([]);
	const noticeColumnList = useMemo(() => [{ name: '제목' }, { name: '작성일' }], []);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	useEffectOnce(() => {
		request(
			BoardService.list,
			['notice', company.get.id],
			(res) => {
				if (res?.data) {
					const response = res.data;
					let sorted = [];
					response.sort((a, b) => {
						if (a.createdAt < b.createdAt) {
							return 1;
						}
						if (a.createdAt > b.createdAt) {
							return -1;
						}
						return 0;
					});
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === true));
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === false && data.notice === true));
					sorted.push(...response.filter((data) => data?.category?.includes('ALL') === false && data.notice === false));
					return { data: sorted };
				}
			},
			(error) => {
				showNotification(t('공지 사항'), t('공지 사항을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
			}
		);
	});

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const isAll = responseData[rowIndex - 1]?.category?.includes('ALL');

			switch (columnIndex) {
				case 0:
					data =
						responseData[rowIndex - 1]?.notice === true || isAll ? (
							<div>
								<span className={`small me-2 ${isAll ? '' : 'noti-badge'}`}>
									{isAll ? <Badge>ita</Badge> : <Icon icon='PushPin' />}
								</span>
								<span>{responseData[rowIndex - 1]?.title || '-'}</span>
								<span>{responseData[rowIndex - 1]?.files?.length > 0 ? <Icon icon='AttachFile' /> : ''}</span>
							</div>
						) : responseData[rowIndex - 1]?.notice === false ? (
							<div>
								<span>{responseData[rowIndex - 1]?.title || '-'}</span>
								<span>{responseData[rowIndex - 1]?.files?.length > 0 ? <Icon icon='AttachFile' /> : ''}</span>
							</div>
						) : (
							''
						);
					break;
				case 1:
					data = timeForToday(responseData[rowIndex - 1]?.createdAt);
					break;
			}

			return data;
		},
		[responseData]
	);

	const timeForToday = (value) => {
		const today = new Date();
		const timeValue = new Date(value);

		const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
		if (betweenTime < 1) return '방금전';
		if (betweenTime < 60) {
			return `${betweenTime}분전`;
		}

		const betweenTimeHour = Math.floor(betweenTime / 60);
		if (betweenTimeHour < 24) {
			return `${betweenTimeHour}시간전`;
		}

		return moment(timeValue).format('L');
	};

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(noticeColumnList[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer ${
							columnIndex === 0 ? 'justify-content-start' : 'justify-content-center'
						} ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
						onClick={() => {
							navigate(`${commonsMenu.notice.path}/${responseData[rowIndex - 1].idx}`);
						}}>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, noticeColumnList, responseData, navigate, renderText, t]
	);

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle className={'cursor-pointer'} onClick={() => navigate(commonsMenu.notice.path)}>
						{t('공지 사항')}
					</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody
				className='overflow-auto dashboard-height'
				style={{ minHeight: '285px' }}
				ref={ref}
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{loading && responseData.length <= 0 ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) : !loading && responseData.length <= 0 ? (
					<div className='text-center py-5 lead'>{t('공지사항이 없습니다.')}</div>
				) : (
					<div className='w-100 h-100'>
						<AutoSizer>
							{({ width, height }) => {
								cellCache.clearAll();
								return (
									<MultiGrid
										classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
										classNameTopRightGrid='border-light border-bottom fw-bold'
										classNameBottomLeftGrid='border-light border-end'
										cellRenderer={cellRenderer}
										columnCount={noticeColumnList.length}
										fixedColumnCount={0}
										fixedRowCount={1}
										rowCount={responseData.length + 1}
										columnWidth={({ index }) =>
											index === 0
												? Math.floor((width - 18 / noticeColumnList.length) * 0.7)
												: Math.floor((width - 18 / noticeColumnList.length) * 0.3)
										}
										rowHeight={cellCache.rowHeight}
										height={height}
										width={width}
									/>
								);
							}}
						</AutoSizer>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default memo(NoticeBoard);
