import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import useDarkMode from '../../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import DonutChart from './DonutChart';
import AdminDashboard from '../../dashboard/component/AdminDashboard';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Page from '../../../layout/Page/Page';
import moment from 'moment';
import ReactSelect from 'react-select';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import { Badge } from '../../../components/icon/material-icons';
import ReactApexChart from 'react-apexcharts';
import Popovers from '../../../components/bootstrap/Popovers';
import * as locales from 'react-date-range/dist/locale';
import {
	addDays,
	format,
	startOfISOWeek,
	endOfISOWeek,
	startOfMonth,
	endOfMonth,
	getISODay,
	endOfWeek,
	startOfWeek,
} from 'date-fns';
import { DateRange } from 'react-date-range';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../components/bootstrap/Modal';
import DeviceService from '../../../services/DeviceService';
import HeaderTop from '../HeaderTop';
import ArDeviceModal from './ArDeviceModal';

const ArDashboardPage = observer(() => {
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);

	const [dailySum, setDailySum] = useState(0);
	const [monthlySum, setMonthlySum] = useState(0);
	const [yearlySum, setYearlySum] = useState(0);
	const [rank, setRank] = useState([]);
	const [chartLabels, setChartLabels] = useState([]);
	const [chartSeries, setChartSeries] = useState([]);
	const [deviceChartData, setDeviceChartData] = useState([]);
	const [devices, setDevices] = useState([]);
	const [status, setStatus] = useState('정상');
	const [params, setParams] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const generateRandomCoordinates = (center, range, numPoints) => {
		const { lat, lng } = center;
		const coordinates = [];

		for (let i = 0; i < numPoints; i++) {
			const randomLat = lat + (Math.random() - 0.5) * range;
			const randomLng = lng + (Math.random() - 0.5) * range;
			coordinates.push({ lat: randomLat, lng: randomLng });
		}

		return coordinates;
	}

	const getData = useCallback(async () => {
		const { data } = await DeviceService.getTopdata(params)
		setDailySum(data.dailySum)
		setMonthlySum(data.monthlySum)
		setYearlySum(data.yearlySum)
		setRank(data.rank)
	}, [params])

	const getChart = useCallback(async () => {
		const { data } = await DeviceService.getChart(params)
		setChartLabels(data.labels)
		setChartSeries(data.series)
	}, [params])

	const getDeviceChart = useCallback(async () => {
		const { data } = await DeviceService.getDeviceChart(params)
		setDeviceChartData(data)
		// setChartLabels(data.labels)
		// setChartSeries(data.series)
	}, [params])

	useEffect(() => {
		getData()
		getChart()
		getDeviceChart()
	}, [getData, getChart, getDeviceChart]);

	let options = {
		title: {
			enabled: true,
			text: '검침값 추이',
			align: 'left'
		},
		legend: {
			show: true
		},
		series: chartSeries,
		colors: [
			'#06C',
			'#5752D1',
			'#C9190B',
			'#4CB140',
			'#009596',
			'#F4C145',
			'#EC7A08',
			'#7D1007',
			'#B8BBBE',
			'#002F5D',
			'#23511E',
			'#003737',
			'#2A265F',
			'#C58C00',
			'#8F4700',
			'#2C0000',
			'#6A6E73',
		],
		labels: chartLabels,
		chart: {
			title: {
				text: '검침값 추이',
				align: 'left'
			},
			type: 'area',
			height: 500,
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			},
			offsetX: -20
		},
		dataLabels: {
			enabled: true
		},
		markers: {
			size: 0,
		},
		title: {
			text: '',
			align: 'center'
		},
		stroke: {
			show: true,
			lineCap: 'butt',
			colors: undefined,
			width: 1,
			dashArray: 0,
		},
		// fill: {
		//     type: 'gradient',
		//     gradient: {
		//         shadeIntensity: 1,
		//         inverseColors: false,
		//         opacityFrom: 0.5,
		//         opacityTo: 0,
		//         stops: [0, 90, 100]
		//     },
		// },
		yaxis: {
			labels: {
				formatter: function (val) {
					return val;
				},
			},
			title: {
				text: '',
				offsetX: 32,
				offsetY: -222,
				rotate: 0,
			},
		},
		xaxis: {
			labels: {
				formatter: function (val, i) {
					return val;
				},
			},
			title: {
				text: ''
			},
		},
		grid: {
			borderColor: '#e7e7e7',
			row: {
				colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
				opacity: 0.5
			},
		},
		tooltip: {
			shared: false,
			y: {
				formatter: function (val) {
					return val + ' m<sup>3</sup>'
				}
			}
		}
	};

	return (
		<PageWrapper className='fluid' title={t('대시보드')}>
			<HeaderTop 
			totalText={'전체 수용가 수: ' + (deviceChartData.length > 0 ? deviceChartData.filter(x => x.count > 0)?.map(m => m?.count || 0)?.reduce((a, b) => a + b) : 0)} 
			setParams={setParams} 
			disableExcelUp={true}
			/>
			<Page container='fluid' >
				<div className='row'>
					<div className='col-xl-9'>
						<div className='row'>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{ padding: 10 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #5d87ff' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#ecf2ff', color: '#5d87ff' }}>
										<CardLabel>
											<CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{'일일 사용량'}(m<sup>3</sup>)</CardTitle>
											<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{dailySum}</CardSubTitle>
										</CardLabel>
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{padding: 10 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #ffae1f' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#fef5e5', color: '#ffae1f' }}>
										<CardLabel>
											<CardTitle style={{ fontSize: '1.5rem', marginTop: 10   }}>{'월간 사용량'}(m<sup>3</sup>)</CardTitle>
											<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{monthlySum}</CardSubTitle>
										</CardLabel>
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{padding: 10 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #49beff' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#e8f7ff', color: '#49beff' }}>
										<CardLabel>
											<CardTitle  style={{ fontSize: '1.5rem', marginTop: 10 }}>{'연간누적 사용량'}(m<sup>3</sup>)</CardTitle>
											<CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{yearlySum}</CardSubTitle>
										</CardLabel>
									</CardBody>
								</Card>
							</div>
							<div className='col-xl-3 col-lg-6 col-md-6' style={{padding: 10 }}>
								<Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #fa896b' }}>
									<CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#fdede8', color: '#fa896b' }}>
										<CardLabel>
											<CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{'최다 사용 수용가'}(m<sup>3</sup>)</CardTitle>
											{ rank.map((x, i) => (
												<CardSubTitle key={i} className='mt-3' style={{ textAlign: 'left', fontSize: '1.0rem', marginTop: 20 }}>{`${i + 1}. ${x.accept_number} (${x.total_water_meter}`}m<sup>3</sup>)</CardSubTitle>
											))}
										</CardLabel>
									</CardBody>
								</Card>
							</div>
						</div>
						<div>
							<div><h4>검침값 추이</h4></div>
							<ReactApexChart
								options={options}
								series={options.series}
								width={'100%'}
								type={'line'}
								height={500}
							/>
						</div>
					</div>
					<div className='col-xl-3 col-lg-3 col-md-3 text-center' style={{ padding: 50 }}>
						<ReactApexChart
							options={{
								title: {
									text: '장비 현황',
									align: 'center'
								},
								colors: deviceChartData.filter((x) => x.count > 0).map((x) => x.color),
								chart: {
									type: 'donut',
									height: 250,
									events: {
										dataPointSelection: (event, chartContext, config) => {
											// 데이터 포인트가 클릭되었을 때 실행될 함수
											console.log("Data Point Clicked: ", config.dataPointIndex);
											console.log("Series Index: ", config.seriesIndex);

											setDevices(deviceChartData.filter((x) => x.count > 0)[config.dataPointIndex].devices)
											setStatus(deviceChartData.filter((x) => x.count > 0)[config.dataPointIndex].label)
											setIsOpen(true);
											// 원하는 동작을 추가하세요
										}
									},
								},
								stroke: {
									width: 0,
								},
								labels: deviceChartData.filter((x) => x.count > 0).map((x) => x.label),
								dataLabels: {
									enabled: false,
								},
								plotOptions: {
									pie: {
										expandOnClick: true,
										donut: {
											labels: {
												show: true,
												name: {
													show: true,
													fontSize: '24px',
													fontFamily: 'Poppins',
													fontWeight: 900,
													offsetY: 0,
													formatter(val) {
														return val;
													},
												},
												value: {
													show: true,
													fontSize: '16px',
													fontFamily: 'Poppins',
													fontWeight: 900,
													offsetY: 16,
													formatter(val) {
														return val;
													},
												},
											},
										},
									},
								},
								legend: {
									show: false,
									position: 'bottom',
									floating: true,
									offsetY: 100,
									height: 200,
									showForSingleSeries: true
								},
							}}
							series={deviceChartData.filter((x) => x.count > 0).map((x) => x.count)}
							width={250}
							type={'donut'}
							height={250}
						/>
						<div style={{padding: 30}}>
							{deviceChartData.filter((x) => x.count > 0).map((x, i) => (
								<div key={i} className='row'>
									<div style={{ backgroundColor: x.color, width: 25, height: 25, margin: 5}} />
									<div className='col text-start pt-2'><b>{x.label} ({x.count})</b></div>
								</div>
							))}
						</div>
					</div>
				</div>
				<ArDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} status={status} />
			</Page>
		</PageWrapper>
	);
});

export default ArDashboardPage;