let host = window.location.host.indexOf('localhost') > -1 ? 'local' 
	: window.location.host.replace('rnslab.bhz.co.kr', '')
	.replace('sensor.bhz.co.kr', '')
	.replace('ar.bhz.co.kr', '')
	.replace('nr.bhz.co.kr', '')
	.replace('sr.bhz.co.kr', '')
	.replace('wr.bhz.co.kr', '')
	.replace('admin.rnslab.com', '')
	.replace('sensor.rnslab.com', '')
if (!host) host = 'prod';
console.log(host)

let appVersion = 'APP_VERSION';
let buildVersion = 'BUILD_VERSION';

const config = {
	local: {
		CLIENT_ID: 'com.rnslab.admin',
		API_URL: 'http://localhost:11061/api/v1',
		API_URL2: 'http://localhost:11063/m/api/v1',
		appVersion,
		buildVersion,
	},
	prod: {
		CLIENT_ID: 'com.rnslab.admin',
		API_URL: 'https://rnslab.bhz.co.kr/api/v1',
		API_URL2: 'https://rnslab.bhz.co.kr/m/api/v1',
		appVersion,
		buildVersion,
	},
};

export default config[host];
