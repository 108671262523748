import React, { createContext, useLayoutEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import useDeviceScreen from '../hooks/useDeviceScreen';
import { getItem, setItem } from '../lib/localstorage';

const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
	const deviceScreen = useDeviceScreen();
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

	const [darkModeStatus, setDarkModeStatus] = useState(process.env.REACT_APP_DARK_MODE === 'true');

	const [fullScreenStatus, setFullScreenStatus] = useState(false);
	const [moreMenuRef, setMoreMenuRef] = useState(null);

	const [leftMenuStatus, setLeftMenuStatus] = useState(false);
	const [rightMenuStatus, setRightMenuStatus] = useState(false);
	const [asideStatus, setAsideStatus] = useState(deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE);
	const [asideSubMenuStatus, setAsdieSubMenuStatus] = useState(false);
	const [asideSubStatus, setAsideSubStatus] = useState(deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE);
	const [asideMenu, setAsideMenu] = useState({});

	const [rightPanel, setRightPanel] = useState(false);
	const [chatMode, setChatMode] = useState(true);
	const [tempUploadFiles, setTempUploadFiles] = useState([]);
	const [directCount, setDirectCount] = useState(0);
	const [favoriteCount, setFavoriteCount] = useState(0);
	const [groupCount, setGroupCount] = useState(0);
	const [chatScrollRef, setChatScrollRef] = useState(null);

	// Progress Toast
	const [isProgressBar, setIsProgressBar] = useState(false);
	const [max, setMax] = useState(0);
	const [value, setValue] = useState(0);

	// default values
	const [noticeCount, setNoticeCount] = useState(0);

	// lang
	const [lang, setLang] = useState(getItem('i18nextLng') ? getItem('i18nextLng') : 'ko');

	// ePaper
	const [eApproval, setEApproval] = useState({
		approvalReportAll: 0,
		approvalReportRequest: 0,
		approvalReportSuccess: 0,
		approvalReceiptAll: 0,
		approvalReceiptRequest: 0,
		approvalReceiptList: 0,
		approvalReceiptProxy: 0,
		approvalReceiptCC: 0,
	});

	useLayoutEffect(() => {
		if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
			setAsideStatus(true);
			setAsideSubStatus(true);

			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
		return () => {
			setAsideStatus(false);
			setAsideSubStatus(false);
		};
	}, [deviceScreen.width]);

	const values = useMemo(
		() => ({
			mobileDesign,
			darkModeStatus,
			setDarkModeStatus,
			fullScreenStatus,
			setFullScreenStatus,
			asideStatus,
			setAsideStatus,
			asideSubStatus,
			setAsideSubStatus,
			asideSubMenuStatus,
			setAsdieSubMenuStatus,
			leftMenuStatus,
			setLeftMenuStatus,
			rightMenuStatus,
			setRightMenuStatus,
			rightPanel,
			setRightPanel,
			chatMode,
			setChatMode,
			moreMenuRef,
			setMoreMenuRef,
			tempUploadFiles,
			setTempUploadFiles,
			directCount,
			setDirectCount,
			favoriteCount,
			setFavoriteCount,
			groupCount,
			setGroupCount,
			chatScrollRef,
			setChatScrollRef,
			asideMenu,
			setAsideMenu,
			isProgressBar,
			setIsProgressBar,
			max,
			setMax,
			value,
			setValue,
			noticeCount,
			setNoticeCount,
			lang,
			setLang,
			// 전자결재
			eApproval,
			setEApproval,
		}),
		[
			asideMenu,
			asideStatus,
			asideSubStatus,
			asideSubMenuStatus,
			darkModeStatus,
			fullScreenStatus,
			leftMenuStatus,
			mobileDesign,
			rightMenuStatus,
			rightPanel,
			chatMode,
			moreMenuRef,
			tempUploadFiles,
			directCount,
			favoriteCount,
			groupCount,
			chatScrollRef,
			isProgressBar,
			max,
			value,
			noticeCount,
			lang,
			// 전자결재
			eApproval,
		]
	);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
