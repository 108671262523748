import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalHeader } from "../../../components/bootstrap/Modal";
import Card, { CardBody, CardHeader } from "../../../components/bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useMst } from "../../../models";

const ArDeviceModal = observer(({isOpen, setIsOpen, devices, status}) => {
    const navigate = useNavigate();
    const { user } = useMst();

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop={true}
            isScrollable={true}
            isCentered={true}
            //fullScreen={true}
            fullScreen={'md'}
            size={'md'}>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <div className={'text-dark'} style={{ fontSize: '1.5rem' }}>
                    <b>장비 현황 목록</b>
                </div>
                <div>
                    <ButtonGroup>
                        <Button
                            type='button'
                            color='dark'
                            icon='Close'
                            onClick={() => {
                                setIsOpen(false);
                            }}>
                            {''}
                        </Button>
                    </ButtonGroup>
                </div>
            </ModalHeader>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <h5>장비 상태 : {status}</h5>
                <h5>{devices.length} 개</h5>
            </ModalHeader>
            <ModalBody style={{ height: '50vh', padding: 5, overflowY: 'auto' }}>
                {devices.map((device, index) => (
                    <div key={`device-modal-${index}`} className='d-flex justify-content-between'
                        style={{ border: '1px solid #5d87ff', backgroundColor: '#ecf2ff', color: '#5d87ff', padding: 15,
                            margin: 10, borderRadius: 10, cursor: 'pointer', fontSize: '1.1rem',
                        }}
                        onClick={() => {
                            console.log('device clicked', device);
                            user.setIMEI(device.IMEI);
                            navigate(`/arUserDataList`);
                        }}
                    >
                        <span>{device.install.acceptNumber}</span>
                        <span>({device.IMEI})</span>
                        <span>감지 시각: {device.updatedAt}</span>
                    </div>
                ))}
            </ModalBody>
        </Modal>
    );
});

export default ArDeviceModal;