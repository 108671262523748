import { useCallback, useState } from 'react';
import moment from 'moment';
import showNotification from '../components/extras/showNotification';
import { useTranslation } from 'react-i18next';

/*
 * requestFunc : Function
 * requestParams : Array
 * thenFunc : then Function
 * sortConfig : Object
 */
const useRequest = (initState = null) => {
	const { t } = useTranslation(['translation', 'menu']);
	const [responseData, setResponseData] = useState(initState);
	const [loading, setLoading] = useState(true);
	const request = useCallback(
		async (requestFunc, requestParams = [], thenFunc, catchFunc, sortConfig) => {
			setLoading(true);
			const response = await requestFunc(...requestParams)
				.then(thenFunc ? thenFunc : (res) => res)
				.catch(
					catchFunc
						? catchFunc
						: (error) => {
								showNotification(t('요청 에러'), t('불러오는 도중 알 수 없는 에러가 발생했습니다.'), 'danger');
								return;
						  }
				);

			try {
				if (response?.data) {
					const sortConfigSetting = {
						//Sort Key : String, Array
						key: sortConfig?.key || null,
						//Key를 반복할 배열이 나올 떄 까지 진입해야 할 위치를 배열로 명시 : Array
						deepDive: sortConfig?.deepDive || null,
						//오름차순 : 'ascending', 내림차순 : 'descending'
						direction: sortConfig?.direction ? sortConfig.direction : 'ascending',
						//타입 : 'date'
						type: sortConfig?.type || null,
					};
					let origin = response.data;
					let sorted = response.data;

					if (sortConfigSetting.deepDive) {
						sortConfigSetting.deepDive.forEach((element) => {
							sorted = sorted[element];
						});
					}

					if (sortConfigSetting.key) {
						sorted.sort((a, b) => {
							let aKey = a;
							let bKey = b;

							if (typeof sortConfigSetting.key === 'string') {
								aKey = a?.[sortConfigSetting.key] || '';
								bKey = b?.[sortConfigSetting.key] || '';
							} else if (Array.isArray(sortConfigSetting.key)) {
								sortConfigSetting.key.forEach((element) => {
									aKey = aKey?.[element] || '';
									bKey = bKey?.[element] || '';
								});
							}
							if (!aKey || !bKey) return;

							if (sortConfigSetting.type) {
								//여기에 여러 타입 추가 가능
								if (sortConfigSetting.type === 'date') {
									if (moment(aKey) < moment(bKey)) {
										return sortConfigSetting.direction === 'ascending' ? -1 : 1;
									}
									if (moment(aKey) > moment(bKey)) {
										return sortConfigSetting.direction === 'ascending' ? 1 : -1;
									}
									return 0;
								}
							} else {
								if (aKey < bKey) {
									return sortConfigSetting.direction === 'ascending' ? -1 : 1;
								}
								if (aKey > bKey) {
									return sortConfigSetting.direction === 'ascending' ? 1 : -1;
								}
								return 0;
							}
						});
					}

					//원본 데이터에 정렬된 데이터 삽입하기
					if (sortConfigSetting.deepDive) {
						sortConfigSetting.deepDive.reduce((obj, key, index) => {
							if (index !== sortConfigSetting.deepDive.length) {
								return obj[key];
							} else {
								obj = sorted;
							}
						}, origin);
						setResponseData(origin);
					} else {
						setResponseData(sorted);
					}
				}
			} catch (error) {
				//console.log('error ====== ', error);
			} finally {
				setLoading(false);
			}
		},
		[t]
	);

	return {
		responseData,
		setResponseData,
		loading,
		setLoading,
		request,
	};
};

export default useRequest;
