import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Avatar from 'react-avatar';
import { useEffectOnce } from 'react-use';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { useMst } from '../../../models';

const MySimpleProfile = observer(() => {
	const { user, company } = useMst();
	const mainDepartment =
		company.get?.departInfos?.toJSON()?.filter((departmentInfo) => departmentInfo.main === true)?.[0] || {};
	const mainDepartmentName = mainDepartment?.departName || '';
	const mainDepartmentPostionName = mainDepartment?.positionName || '';

	return (
		<Card className={`shadow-3d-info`} stretch>
			<CardBody className='d-flex justify-content-center align-items-center dashboard-height overflow-auto'>
				<div className='text-center'>
					<div className='py-1'>
						<Avatar
							round
							textSizeRatio={0.2}
							size={150}
							className='me-2'
							name={user.me?.name || ''}
							src={user.me?.profile || null} // user.me 스토어에 넣어놓기
						/>
					</div>
					<div className='fw-bold fs-3 mt-3'>{user.me?.name || ''}</div>
					<div>{user?.me?.email || ''}</div>
					<div>{user?.me?.phone || ''}</div>
					<div className='fw-light text-dark'>{mainDepartmentName}</div>
					<div>{`${mainDepartmentPostionName} | ${user?.me?.userRank || ''}`}</div>
					<div>
						<span className='fw-bold'>{company.get?.restInfo?.joinYear || 0}</span>년차,{' '}
						<span>
							{`남은 연차(${
								(company.get?.restInfo?.restY || 0) +
								(company.get?.restInfo?.restM || 0) +
								(company.get?.restInfo?.extraRestDay || 0) -
								((company.get?.restInfo?.reqRest || 0) + (company.get?.restInfo?.susRest || 0))
							}) / 총 연차(${
								(company.get?.restInfo?.restY || 0) +
								(company.get?.restInfo?.restM || 0) +
								(company.get?.restInfo?.extraRestDay || 0)
							})`}
						</span>
					</div>
				</div>
			</CardBody>
		</Card>
	);
});

export default MySimpleProfile;
