import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import ThemeContext from '../../contexts/themeContext';
import Icon from '../../components/icon/Icon';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import { Link, useNavigate } from 'react-router-dom';
import { useMst } from '../../models';
import { observer } from 'mobx-react';
import { setItem, getItem } from '../../lib/localstorage';
import contents from '../../routes/contentRoutes';

const Aside = observer(() => {
	const { asideStatus, setAsideStatus, asideSubStatus, setAsideSubStatus, asideSubMenuStatus, asideMenu, noticeCount } = useContext(ThemeContext);
	const { asideStyle, asideSubStyle, touchStatus, hasTouchButton, asideWidthWithSpace, asideSubWidthWithSpace, x, x2 } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { user } = useMst();
	const navigation = useNavigate();

	const [menus, setMenus] = useState({})

	// useEffect(() => {
	// 	// console.log("----------------", JSON.stringify([]));
	// 	const navTabls = JSON.parse(getItem(`navTabMenu_${company.get.id}_${company.get.isDoc ? 'admin' : 'user'}`) || JSON.stringify([]));
	// 	// console.log('load tabs', navTabls);
	// 	if (navtabmenus?.setMenus) navtabmenus?.setMenus(navTabls);

	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [ navtabmenus]);

	useEffect(() => {
		let menu = {} 
		
		console.log(user.me)
		console.log(contents)
		if (user.me.type !== 'SUPERADMIN') {
			if (user.me.model) {
				Object.entries(contents[user.me.model]).map(([key, page]) => {
					menu[key] = page
				})
			}
		} else {
			Object.entries(contents.SUPERADMIN).map(([key, page]) => {
				menu[key] = page
			})
		}
		setMenus(menu)
	}, [user.me])

	console.log(user.me)

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					}
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} asideSubStatus={asideSubStatus} setAsideSubStatus={setAsideSubStatus} />
				</div>
				<div className='aside-body'>
					{/* 권한에 따른 메뉴 표시 */}
					{ user.me.type === 'SUPERADMIN' && (
						<Navigation menu={menus} id='aside-dashboard' />
					)}
					{ ['USER', 'GROUP'].indexOf(user.me.type) > -1 && (
						<Navigation menu={menus} id='aside-dashboard' />
					)}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						{/* <Navigation menu={footsMenu} id='aside-foots' /> */}
						{/* <div className='navigation'>
							<div
								className='navigation-item cursor-pointer'
								onClick={() => {
									window.open(`https://rnslab.bhz.co.kr/`, '_blank');
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon={'LibraryBooks'} className='navigation-icon color-blue-light' />
										<span className='navigation-text'>{t('이용 가이드')}</span>
									</span>
								</span>
							</div>
						</div> */}

						{user.type === 'SUPERADMIN' && (
							<div className={`navigation admin-state`}> {/* user-state */}
								<div
									role='presentation'
									className='navigation-item cursor-pointer'
									onClick={() => {
										navigation('/');
									}}
									data-tour='documentation'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info text-white'>
											<Icon
												icon={'ToggleOn'}
												className='navigation-icon'
											/>
											<span className='navigation-text'>{t('관리자 상태')}</span>
										</span>
									</span>
								</div>
							</div>
						)}
					</nav>
					<User />
				</div>
			</motion.aside>

			{/* SUB */}
			{asideSubMenuStatus && (
				<motion.aside
					style={asideSubStyle}
					className={classNames(
						'aside-sub',
						//{ 'd-none': !asideSubStatus && hasTouchButton && isModernDesign },
						{ open: asideSubStatus },
						{
							'aside-touch-bar': hasTouchButton && isModernDesign,
							'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
							'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
						}
					)}>
					<div className='aside-sub-head'></div>
					<div className='aside-sub-body'>
						<Navigation menu={asideMenu} id='aside-sub-dashboard' />
					</div>
					<div className='aside-sub-foot'></div>
				</motion.aside>
			)}

			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title={t('Toggle Aside')} flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => {
								// console.log('x.get()', x.get())
								// console.log('x2.get()', x2.get())
								// console.log('>>', x2.get() === 0 ? asideSubWidthWithSpace : 0)
								x.set(x.get() === 0 ? asideWidthWithSpace : 0);
								x2.set(x2.get() === 0 ? asideSubWidthWithSpace : 0);
							}}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
});

export default Aside;
