import React, { memo, useState, useReducer, useEffect, useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models'; //스토어 로그인 유저 회사번호 불러올 때 사용
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import CollectedDataList from './ArCollectedDataList';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import SubHeader, { SubHeaderCenter, SubHeaderLeft, SubHeaderRight } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import useFilter from '../../../hooks/useFilter';
import { object } from 'prop-types';
import Popovers from '../../../components/bootstrap/Popovers';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import HeaderTop from '../HeaderTop';
import Badge from '../../../components/bootstrap/Badge';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, MultiGrid } from 'react-virtualized';
import PacketService from '../../../services/PacketService';
import DeviceService from '../../../services/DeviceService';
import ReactApexChart from 'react-apexcharts';

let isLoaded = [];
const ArCollectedData = () => {
	const { t } = useTranslation(['translation', 'menu']);
	const listRef = useRef();
	const { user } = useMst();
	const [params, setParams] = useState({});
	const [loading, setLoading] = useState(true);
	const [filterData, setFilterData] = useState([]);
	const [scrollLeft, setScrollLeft] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	const [chartSeries, setChartSeries] = useState([]);
	const [info, setInfo] = useState({});
	const [IMEI, setIMEI] = useState('');
	const [totalWaterMeter, setTotalWaterMeter] = useState(0);

	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				fixedWidth: false,
				defaultHeight: 50,
				minWidth: 100,
				fixedHeight: false,
			}),
		[]
	);

	const columns = useMemo(
		() => [
			// { grow: 0, minWidth: 50, maxWidth: 70, name: '번호' },
			{ grow: 1, minWidth: 200, name: '저장일자', key1: 'createdAt' },
			{ grow: 1, minWidth: 200, name: '수용가 명', key1: 'customerName' },
			{ grow: 1, minWidth: 200, name: '수용가 번호', key1: 'acceptNumber' },
			{ grow: 1, minWidth: 200, name: '주소', key1: 'customerAddress' },
			{ grow: 1, minWidth: 30, name: '계량기 번호', key1: 'IDENT_NR' },
			{ grow: 1, minWidth: 30, name: '계량기 구경', key1: 'Diameter' },
			{ grow: 1, minWidth: 30, name: '보고 주기', key1: 'AR_term' },
			{ grow: 1, minWidth: 30, name: '검침 주기', key1: 'SR_term' },
			{ grow: 1, minWidth: 30, name: '검침 시간', key1: 'Sensing_Time' },
			{ grow: 1, minWidth: 30, name: '검침값(m³)', key1: 'Water_Meter' },
			{ grow: 1, minWidth: 30, name: '검침값 이력(m³)', key1: 'Water_Meter' },
			{ grow: 1, minWidth: 30, name: '온도', key1: 'Temperature_In' },
			{ grow: 1, minWidth: 30, name: '습도', key1: 'Humidity_In' },
			{ grow: 1, minWidth: 30, name: '통신성공률', key1: 'E_RATE' },
			{ grow: 1, minWidth: 30, name: '기기 상태', key1: 'Error' },
			{ grow: 1, minWidth: 30, name: '배터리(V)', key1: 'BAT' },
			{ grow: 1, minWidth: 30, name: '탁도(NTU)', key1: 'NTU' },
			{ grow: 1, minWidth: 200, name: 'IMEI', key1: 'IMEI' },
			{ grow: 1, minWidth: 30, name: 'SERIAL', key1: 'SerialNumber' },
			{ grow: 1, minWidth: 30, name: 'RSSI', key1: 'RSSI' },
			{ grow: 1, minWidth: 30, name: 'RSRP', key1: 'RSRP' },
			{ grow: 1, minWidth: 30, name: 'RSRQ', key1: 'RSRQ' },
			{ grow: 1, minWidth: 30, name: 'SNR', key1: 'SNR' },
			{ grow: 2, minWidth: 30, name: '펌웨어 버전', key1: 'FW_Version' },
		],
		[]
	);

	const TDCell = useCallback(
		({ columnData, rowData, columnIndex, rowIndex, dataKey }) => {
			let data = null;
			switch (dataKey) {
				case 'IMEI':
					data = columnData ?
						(
							<Badge
								style={{ fontSize: 12 }}
								color='dark'
								onClick={() => {
									if (['USER', 'GROUP'].indexOf(user.me.type) < 0) {
										setDetailData(rowData)
										setArrayModalOpen(true)
									}
								}}>
								{columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Water_Meter':
					data = columnData ?
						(
							<Badge
								color='primary'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'MIC':
					data = columnData ?
						(
							<Badge
								color='success'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'XYZ':
					data = columnData ?
						(
							<Badge
								color='danger'
								onClick={() => {
									setDetailData(Array.isArray(columnData) ? columnData : [columnData])
									setArrayModalOpen(true)
								}}>
								{columnData[0] || columnData}
							</Badge>
						) : 'N/A'
					break
				case 'Error':
					data = columnData ? '0x' + parseInt(columnData, 2).toString(16).padStart(8, '0') : '0'
					break;
				case 'customerName':
					data = rowData?.install?.customerName || 'N/A';
					break;
				case 'customerAddress':
					data = rowData?.install?.customerAddress || 'N/A';
					break;
				case 'acceptNumber':
					data = rowData?.install?.acceptNumber || 'N/A';
					break;
				default:
					data = columnData || 'N/A';
					break;
			}

			return data;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData]
	);

	const cellRenderer = useCallback(
		({ columnIndex, key, rowIndex, style, parent }) => {
			return (
				<CellMeasurer key={key} cache={cellCache} parent={parent} columnIndex={columnIndex} rowIndex={rowIndex}>
					{rowIndex === 0 ? (
						<div style={style} className={`py-3 text-nowrap text-center ${rowIndex === 0 ? '' : 'border-bottom border-end'}`}>
							{columns[columnIndex]?.name}
						</div>
					) : (
						<div
							style={style}
							className={`p-2 text-nowrap text-center border-bottom border-end cursor-pointer ${rowIndex % 2 === 0 ? 'bg-l50-light' : ''}`}
							onClick={() => {
								setSelectedUesr(filterData[rowIndex - 1]);
								// setIsUserDetailOpen(true);
							}}>
							{TDCell({
								columnData: filterData[rowIndex - 1][columns[columnIndex]?.key1],
								columnIndex,
								dataKey: columns[columnIndex]?.key1,
								parent,
								rowData: filterData[rowIndex - 1],
								rowIndex,
							})}
						</div>
					)}
				</CellMeasurer>
			);
		},
		[filterData, columns, cellCache, TDCell]
	);

	const onScrollHandler = (e) => {
		if (!loading && e.scrollTop + e.clientHeight >= e.scrollHeight - 300 && totalCount >= filterData.length) {
			if (isLoaded.indexOf(filterData.length) < 0) {
				isLoaded.push(filterData.length);
				getData();
			}
		} else {
			// const keysToCheck = ['id', 'name']; // 체크할 키들
			// const isDuplicate = checkDuplicate(originData, keysToCheck);
			// console.log(isDuplicate); // true
		}

		setScrollLeft(e.scrollLeft)
	};

	const getData = useCallback(async (load) => {
		// console.log(filterButtonContainer);
		setLoading(true);
		if (load === 'init') isLoaded = [];
		const { data } = await PacketService.getPacketList2(params)
		setFilterData(data.list)
		setTotalCount(data.totalCount)
		setFilterCount(data.filterCount)
		const total = data.list.reduce((sum, current) => sum + current.Water_Meter[0], 0);

		setTotalWaterMeter(total.toFixed(3))
		setLoading(false);
	}, [params])

	const getInfo = useCallback(async () => {
		const { data } = await DeviceService.getInfo({ IMEI })
		console.log(data);
		setInfo(data)
	}, [IMEI])

	useEffect(() => {
		console.log('useEffect - IMEIs')
		getInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [IMEI])

	useEffect(() => {
		getData('init');
		console.log('useEffect - params')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params])

	useEffect(() => {
		setIMEI(user.me.IMEI)
	}, [user.me])

	console.log(filterData.map((d, i) => d.Water_Meter[0]))

	let options = {
		title: {
			enabled: true,
			text: '검침값 추이',
			align: 'left'
		},
		legend: {
			show: true
		},
		series: [{
			name: '검침값',
			data: filterData.map((d, i) => d.Water_Meter[0]),
		}],
		colors: [
			'#06C',
			'#B8BBBE',
			'#5752D1',
			'#C9190B',
			'#4CB140',
			'#009596',
			'#F4C145',
			'#EC7A08',
			'#7D1007',
			'#002F5D',
			'#23511E',
			'#003737',
			'#2A265F',
			'#C58C00',
			'#8F4700',
			'#2C0000',
			'#6A6E73',
		],
		labels: filterData.map(d => d.Sensing_Time),
		chart: {
			title: {
				text: '검침값 추이',
				align: 'left'
			},
			type: 'area',
			height: 500,
			zoom: {
				enabled: false
			},
			toolbar: {
				show: false
			},
			offsetX: -20
		},
		dataLabels: {
			enabled: true
		},
		markers: {
			size: 0,
		},
		title: {
			text: '',
			align: 'center'
		},
		stroke: {
			show: true,
			curve: 'smooth',
			lineCap: 'butt',
			width: 0,
			dashArray: 0,
		},
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: "vertical",
				opacityFrom: 0.4,
				opacityTo: 1,
				colorStops: []
			},
		},
		// stroke: {
		// 	show: true,
		// 	lineCap: 'butt',
		// 	colors: undefined,
		// 	width: 1,
		// 	dashArray: 0,
		// },
		// fill: {
		//     type: 'gradient',
		//     gradient: {
		//         shadeIntensity: 1,
		//         // inverseColors: false,
		//         opacityFrom: 0.5,
		//         opacityTo: 0,
		//         stops: [0, 90, 100]
		//     },
		// },
		yaxis: {
			labels: {
				formatter: function (val) {
					return val;
				},
			},
			title: {
				text: '',
				offsetX: 32,
				offsetY: -222,
				rotate: 0,
			},
		},
		xaxis: {
			labels: {
				formatter: function (val, i) {
					return val;
				},
			},
			title: {
				text: ''
			},
		},
		grid: {
			borderColor: '#e7e7e7',
			row: {
				colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
				opacity: 0.5
			},
		},
		tooltip: {
			shared: false,
			y: {
				formatter: function (val) {
					return val + ' m<sup>3</sup>'
				}
			}
		}
	};

	console.log(params)

	return (
		<PageWrapper className='full' title={t('수집 데이터')}>
			<HeaderTop setParams={setParams} disableExcelUp disableArea disableDeviceStatus enableTab />
			<Page container='fluid'>
				<div className='row' style={{display: 'flex', flex: 1}}>
					<div className='col-xl-2 col-lg-2 col-md-2 text-center' style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
						<Card className='rounded-2 align-items-center'
							style={{ 
								fontSize: '1.2rem', border: '1px solid #5d87ff', 
								backgroundColor: '#ecf2ff', color: '#5d87ff', padding: 0, flex: 1,
							}}>
							<CardBody className='w-100'>
								<h3>수용가 정보</h3>
								<div className='w-100 text-left' style={{marginTop: 30}}>
									<p>계량기 번호: {info?.packets ? info?.packets[0]?.IDENT_NR : ''}</p>
									<p>수용가 번호: {info?.install?.acceptNumber || ''}</p>
									<p>수용가 명: {info?.install?.customerName || ''}</p>
									<p>주소: {info?.install?.customerAddress || ''}</p>
								</div>
							</CardBody>
						</Card>
						<Card className='rounded-2 align-items-center'
							style={{
								fontSize: '1.2rem', border: '1px solid #fa896b', marginTop: 30,
								backgroundColor: '#fdede8', color: '#fa896b', padding: 0, flex: 1,
							}}>
							<CardBody className='w-100'>
								<h3>기기 정보</h3>
								<div className='w-100 text-left' style={{ marginTop: 30 }}>
									<p>기기 상태: {info?.ErrorStatus?.map(e => e.label).join(',')}</p>
									<p>온도: {info?.packets ? info?.packets[0]?.Temperature_In : ''}℃</p>
									<p>습도: {info?.packets ? info?.packets[0]?.Humidity_In : ''}%</p>
									<p>배터리: {info?.packets ? info?.packets[0]?.BAT : ''}%</p> 
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-9' style={{ flex: 1, display: 'flex', flexDirection: 'column' }} >
						<div className='w-100 text-center'>
							<h4>기간 내 검침값: {totalWaterMeter} (m³)</h4>
						</div>
						<div className='w-100' style={{ flex: 1, display: 'flex' }}>
							{(params.selectedTab === 'DATA' && filterData?.length > 0) && (
								<AutoSizer
									onResize={() => {
										listRef.current.recomputeGridSize();
									}}>
									{({ width, height }) => {
										// console.log(width, height);
										return (
											<ColumnSizer columnCount={columns.length} columnMaxWidth={300} width={width}>
												{({ adjustedWidth, getColumnWidth, registerChild }) => (
													<div className=''>
														<MultiGrid
															ref={listRef}
															// deferredMeasurementCache={cellCache}
															cellRenderer={cellRenderer}
															classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
															classNameTopRightGrid='border-light border-bottom fw-bold'
															classNameBottomLeftGrid='border-light border-end'
															// classNameBottomRightGrid="border border-0 border-light border-end-1"
															fixedColumnCount={3}
															fixedRowCount={1}
															height={height}
															width={width}
															overscanRowCount={5}
															overscanColumnCount={5}
															// columnCount={Object.keys(data[0]).length}
															columnCount={columns.length}
															columnWidth={cellCache.columnWidth}
															rowCount={filterData.length + 1}
															rowHeight={cellCache.rowHeight}
															// isScrolling={isScrolling}
															onScroll={onScrollHandler}
															scrollLeft={scrollLeft}
														/>
													</div>
												)}
											</ColumnSizer>
										);
									}}
								</AutoSizer>
							)}
							{(params.selectedTab === 'GRAPH' && filterData?.length > 0) && (
								<ReactApexChart
									style={{ flex: 1, paddingLeft: 70 }}
									height={700}
									options={options}
									series={options.series}
									type={'area'}
								/>
							)}
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default memo(ArCollectedData);
