import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import useDarkMode from '../../../hooks/useDarkMode';
import { useTranslation } from 'react-i18next';
import AdminDashboard from './../component/AdminDashboard';
import UserDashboard from './../component/UserDashboard';
import { observer } from 'mobx-react';
import { useEffectOnce } from 'react-use';

const DashboardPage = observer(() => {
	const { user, company, notifiction } = useMst();
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const { themeStatus, darkModeStatus } = useDarkMode();

	return (
		<PageWrapper title={t('대시보드')}>
			<AdminDashboard />
		</PageWrapper>
	);
});

export default DashboardPage;
