import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import ReactApexChart from 'react-apexcharts';
import DeviceService from '../../../services/DeviceService';
import HeaderTop from '../HeaderTop';
import Icon from '../../../components/icon/Icon';
import Button from '../../../components/bootstrap/Button';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import User from '../../../layout/User/User';
import ArDeviceModal from './ArDeviceModal';
import ArUsage from './ArUsage';
import MapModal from '../MapModal';
import { use } from 'i18next';
import PacketService from '../../../services/PacketService';
import ReactSelect from 'react-select';
import { setDate } from 'date-fns';
import moment from 'moment';

const datas = [
    { label: '온도', type: 'Temperature_In', icon: 'Thermometer', value: 25, unit: '℃'},
    { label: '습도', type: 'Humidity_In', icon: 'Water', value: 50, unit: '%'},
    { label: '지온', type: 'Ground_Temperature', icon: 'Thermometer', value: 25, unit: '℃'},
    { label: '지습', type: 'Ground_Humidity', icon: 'Water', value: 50, unit: '%'},
    { label: 'ph', type: 'pH', icon: 'ph', value: 7, unit: ''},
    { label: 'EC', type: 'EC', icon: 'EC', value: 1.5, unit: 'dS/m'},
    { label: 'CO2', type: 'CO2', icon: 'CO2', value: 500, unit: 'ppm'},
]

const dateTypes = [
    { value: 'daily', label: '일간' },
    { value: 'monthly', label: '월간' },
    { value: 'yearly', label: '연간' },
]

const MainPage = observer(() => {
    const { user, company, notifiction } = useMst();
    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'menu']);

    const [dailySum, setDailySum] = useState(0);
    const [monthlySum, setMonthlySum] = useState(0);
    const [yearlySum, setYearlySum] = useState(0);
    const [rank, setRank] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [chartSeries, setChartSeries] = useState([]);
    const [deviceChartData, setDeviceChartData] = useState([]);
    const [favoriteDevice, setFavoriteDevices] = useState([]);
    const [devices, setDevices] = useState([]);
    const [status, setStatus] = useState('정상');
    const [params, setParams] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [viewTab, setViewTab] = useState('MAP');
    const [detailViewDevice, setDetailViewDevice] = useState({});

    const [selectedIndex, setSelectedIndex] = useState(0);

    const generateRandomCoordinates = (center, range, numPoints) => {
        const { lat, lng } = center;
        const coordinates = [];

        for (let i = 0; i < numPoints; i++) {
            const randomLat = lat + (Math.random() - 0.5) * range;
            const randomLng = lng + (Math.random() - 0.5) * range;
            coordinates.push({ lat: randomLat, lng: randomLng });
        }

        return coordinates;
    }

    const getDevice = useCallback(async () => {
        const { data } = await DeviceService.getDeviceList()

        setDevices(data.list)
        setFavoriteDevices(data.list.filter(d => d.favorite))
    }, [])

    useEffect(() => {
        getDevice()
    }, [getDevice]);

    useEffect(() => {
        if (devices.length > 0) {
            getMaps(37.205, 127.106, 3)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    const getDevicePacket = useCallback(async (device) => {
        if (device.IMEI) {
            params.imei = device.IMEI
            params.perPage = 1000
            console.log(params)
            const { data } = await PacketService.getPacketList2(params)
            console.log(data)
        setDeviceChartData(data.list.reverse())
        }
    }, [params])

    useEffect(() => {
        getDevicePacket(detailViewDevice)
    }, [detailViewDevice, getDevicePacket, params]);

    const updateDevices = useCallback((device) => {
        console.log('updateDevices', devices);
        DeviceService.updateDevices({ devices: [device] }).then((res) => {
            console.log('updateDevices', res);
            getDevice()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    useEffect(() => {
        if (search === '') {
            setFavoriteDevices(devices.filter(d => d.favorite));
            return;
        }
        setFavoriteDevices(devices.filter(d => d.favorite).filter((device) => {
            return device.IMEI.includes(search) || device.SerialNumber.includes(search) || device.install.installName.includes(search);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search]);

    const getMaps = useCallback((latitude, longitude, radius) => {
        kakao.maps.load(async () => {
            // console.log('get map');
            let container = document.getElementById('kakaoMap');
            let options = {
                center: new kakao.maps.LatLng(latitude, longitude),
                level: radius,
            };

            const map = new window.kakao.maps.Map(container, options);

            // 위성지도 설정
            map.setMapTypeId(window.kakao.maps.MapTypeId.HYBRID);

            const greenMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png', // 초록색 마커 이미지 URL
                new window.kakao.maps.Size(24, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            const redMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 빨간색 마커 이미지 URL
                new window.kakao.maps.Size(30, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );


            const center = { lat: 37.205, lng: 127.106 };
            const range = 0.02; // ±0.01 정도의 범위
            const numPoints = 10;

            const randomCoordinates = generateRandomCoordinates(center, range, numPoints);

            randomCoordinates.forEach((coordinate, i) => {
                const marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(coordinate.lat, coordinate.lng),
                    image: i % 2 === 0 ? greenMarkerImage : redMarkerImage,
                });
                marker.setMap(map);
            });

            // const circle = new kakao.maps.Circle({
            // 	center: new kakao.maps.LatLng(latitude, longitude), // 원의 중심좌표 입니다
            // 	radius: radius, // 미터 단위의 원의 반지름입니다
            // 	strokeWeight: 1, // 선의 두께입니다
            // 	strokeColor: '#75B8FA', // 선의 색깔입니다
            // 	strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            // 	strokeStyle: 'solid', // 선의 스타일 입니다
            // 	fillColor: '#CFE7FF', // 채우기 색깔입니다
            // 	fillOpacity: 0.7, // 채우기 불투명도 입니다
            // });

            // circle.setMap(map);
        });
    }, []);

    let options = {
        title: {
            enabled: true,
            text: '검침값 추이',
            align: 'left'
        },
        legend: {
            show: true
        },
        series: [{
            name: '검침값',
            data: deviceChartData.map(d => d[datas[selectedIndex].type])
        }],
        labels: deviceChartData.map(d => moment(d.updatedAt).format('MM/DD HH:mm:ss')),
        colors: [
            '#06C',
            '#B8BBBE',
            '#5752D1',
            '#C9190B',
            '#4CB140',
            '#009596',
            '#F4C145',
            '#EC7A08',
            '#7D1007',
            '#002F5D',
            '#23511E',
            '#003737',
            '#2A265F',
            '#C58C00',
            '#8F4700',
            '#2C0000',
            '#6A6E73',
        ],
        chart: {
            title: {
                text: '검침값 추이',
                align: 'left'
            },
            type: 'line',
            height: 500,
            zoom: {
                enabled: true
            },
            toolbar: {
                show: true
            },
            offsetX: -20
        },
        dataLabels: {
            enabled: true,
            formatter: (value, { dataPointIndex }) => {
                if (value === Math.max(...deviceChartData.map(d => d[datas[selectedIndex].type])) || value === Math.min(...deviceChartData.map(d => d[datas[selectedIndex].type]))) {
                    return value// + ' ' + moment(deviceChartData[dataPointIndex].updatedAt).format('MM/DD HH시');
                } else {
                    return '';
                }
            },
            style: {
                colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7']
            }
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 1,
            dashArray: 0,
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shade: 'light',
        //         type: "vertical",
        //         opacityFrom: 0.4,
        //         opacityTo: 1,
        //         colorStops: []
        //     },
        // },
        // stroke: {
        // 	show: true,
        // 	lineCap: 'butt',
        // 	colors: undefined,
        // 	width: 1,
        // 	dashArray: 0,
        // },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shadeIntensity: 1,
        //         // inverseColors: false,
        //         opacityFrom: 0.5,
        //         opacityTo: 0,
        //         stops: [0, 90, 100]
        //     },
        // },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: '',
                offsetX: 32,
                offsetY: -222,
                rotate: 0,
            },
        },
        xaxis: {
            tickAmount: 6,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
            },
            title: {
                text: ''
            },
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val + ' m<sup>3</sup>'
                }
            }
        }
    };

    return (
        <PageWrapper className='fluid' title={t('대시보드')} >
            <div className='row'>
                <div className='col-xl-2 col-lg-2 col-md-2 text-center' >
                    <Card className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #49beff', backgroundColor: '#e8f7ff' }}>
                        <CardBody className={`rounded-2 align-items-center text-center`}>
                            <div style={{ paddingTop: 10, cursor: 'pointer'}} onClick={() => {
                                setViewTab('MAP')
                            }}>
                                <h2>{user.me.nickname || user.me.name}</h2>
                            </div>
                            <Button className={'d-flex w-100 text-center'} color='primary' onClick={() => {
                                setIsOpen(true)
                            }}
                                style={{ marginTop: 23, borderRadius: 5, fontSize: '2.0rem'}}
                            >
                                <div className='w-100 d-flex flex-row justify-content-center text-center'>
                                    {'+'}
                                </div>
                            </Button>
                            <InputGroup style={{marginTop: 10}}>
                                <Input type='text' placeholder='단말기 이름 / IMEI / Serial Number' 
                                    style={{borderRadius: 5}}
                                    value={search} onChange={(e) => {
                                        setSearch(e.target.value);
                                    }} />
                            </InputGroup>
                            <div style={{height: '80vh', overflowY: 'auto'}}>
                                {favoriteDevice.map((d, i) => (
                                    <Card key={i} className={`custom-box-shadow rounded-2`}
                                        style={{ border: '1px solid #5d87ff', marginTop: 10, cursor: 'pointer'}}
                                        onClick={(e) => {
                                            console.log('click', d);
                                            setDetailViewDevice(d)
                                            setViewTab('DETAIL')
                                        }}
                                    >
                                        <CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#ecf2ff', color: '#5d87ff' }}>
                                            <div className='d-flex flex-row justify-content-between'>
                                                <div><CardTitle style={{ fontSize: '1.5rem', marginTop: 0 }}>{d?.install?.installName || ''}</CardTitle></div>
                                                <div>
                                                    <Icon icon='Settings' fontSize={25} />
                                                    <Icon icon='Close' fontSize={25} onClick={(e) => {
                                                        e.stopPropagation()
                                                        e.preventDefault()
                                                        e.nativeEvent.stopPropagation()
                                                        e.nativeEvent.preventDefault()
                                                        d.favorite = false
                                                        updateDevices(d)
                                                    }} style={{cursor:'pointer'}} />
                                                </div>
                                            </div>
                                            <div className='w-100 text-left' style={{ marginTop: 0 }}>
                                                <div>IMEI: {d?.IMEI}</div>
                                                <div>S/N: {d?.SerialNumber}</div>
                                                <div>온도: {deviceChartData.length > 0 ? deviceChartData[0]?.Temperature_In : ''}℃ 습도: {deviceChartData.length > 0  ? deviceChartData[0]?.Humidity_In : ''}%</div>
                                                <div>지온: {deviceChartData.length > 0  ? deviceChartData[0]?.Ground_Temperature : ''}℃ 지습: {deviceChartData.length > 0  ? deviceChartData[0]?.Ground_Humidity : ''}%</div>
                                                <div>ph: {deviceChartData.length > 0  ? deviceChartData[0]?.pH : ''} EC: {deviceChartData.length > 0  ? deviceChartData[0]?.EC : ''}dS/m CO2: {deviceChartData.length > 0  ? deviceChartData[0]?.CO2 : ''}ppm</div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))}
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className='row col-xl-10'>
                    <>
                        <div className='col-xl-6' style={{ display: (viewTab !== 'MAP') ? 'none' : ''}}>
                            <div className='d-flex flex-row justify-content-between' style={{ marginTop: 10, marginBottom: 10 }} >
                                <div><span style={{ fontSize: '1.5rem', marginTop: 0 }}>전체 단말기 수 : {devices.length}</span></div>
                                <div>
                                    <Button color='light' onClick={() => setViewTab('DATA')}>수집 데이터 보기</Button>  
                                </div>
                                <div>
                                    <span style={{ fontSize: '1.5rem', marginTop: 0 }}>이상 단말기 수 : {devices.length}개</span>
                                </div>
                            </div>
                            <div
                                className='position-relative border w-100 d-inline-block bg-l25-light border'
                                style={{
                                    marginTop: 10,
                                    width: '100%',
                                    height: '70vh',
                                }}>
                                <div id='kakaoMap' className='kakao-map' style={{ width: '100%', height: '70vh' }}></div>
                            </div>
                            <div>
                                <div className='d-flex flex-row justify-content-between'>
                                    <h4>이상 알림 내역</h4>
                                    <Button color='info' onClick={() => {}}>전체 내역</Button>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-6 text-center' style={{ display: (viewTab !== 'MAP') ? 'none' : '' }}>
                            <div className='d-flex flex-row justify-content-end'>
                                <div>
                                    <Button style={{ marginRight: 30 }} size={'lg'} color='light' icon={'Person'} onClick={() => {}}>{user.me.name}</Button>
                                </div>
                                <div>
                                    <Button size={'lg'} color='light' icon={'Settings'} onClick={() => {}}></Button>
                                </div>
                            </div>
                            {/* <iframe id='weatherIframe' src="https://www.weather.go.kr/w/weather/forecast/short-term.do?nolayout=Y" width={'100%'} height={'100%'} title="KMA Weather"></iframe> */}

                            <iframe id='weatherIframe' src="https://www.weather.go.kr/w/iframe/dfs.do" width={'100%'} height={'70%'} title="KMA Weather"></iframe>
                        </div>
                    </>
                    {(viewTab === 'DATA') && (
                        <ArUsage />
                    )}
                    {(viewTab === 'DETAIL') && (
                        <>
                            <div className='col-xl-9'>
                                <div className='d-flex flex-row justify-content-between' style={{marginTop: 60}}>
                                    <h4>단말기 실시간 데이터</h4>
                                    <div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div style={{marginRight: 10}}>
                                                <div className='d-flex flex-row justify-content-between' style={{ width: 280}}>
                                                    <div>IMEI: {detailViewDevice.IMEI}</div>
                                                    <div>S/N: {detailViewDevice.SerialNumber}</div>
                                                </div>
                                                <div>마지막 수신 데이터: {detailViewDevice.updatedAt}</div>
                                            </div>
                                            <MapModal />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: 10 }} className='d-flex flex-row justify-content-around'>
                                { datas.map((data, i) => (
                                    (deviceChartData.length > 0 && deviceChartData[0][data.type]) && (
                                    <div key={i} className='d-flex' onClick={() => setSelectedIndex(i)} style={{cursor: 'pointer'}}>
                                        <Card stretch className={`custom-box-shadow rounded-2 d-flex`} style={{ border: '1px solid ' + (selectedIndex === i ? '#fa896b' : '#aaaaaa'), flex: 1, minWidth:100 }}>
                                            <CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: (selectedIndex === i ? '#fdede8' : '#ffffff'), color: (selectedIndex === i ? '#fa896b' : '#aaaaaa') }}>
                                                <CardLabel>
                                                    <CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{data.label}</CardTitle>
                                                        <CardSubTitle className='mt-3' style={{ fontSize: '1.0rem', marginTop: 20 }}>{deviceChartData.length > 0 ? deviceChartData[0][data.type] : ''}{data.unit}</CardSubTitle>
                                                </CardLabel>
                                            </CardBody>
                                        </Card>
                                    </div>)
                                ))}
                                </div>
                                <div className='d-flex flex-row' style={{marginLeft: 50}}>
                                    <HeaderTop setParams={setParams} disableSecond disableMap disableExcelUp disableExcelDown disalbeTotalText />
                                </div>
                                <ReactApexChart
                                    style={{ flex: 1, paddingLeft: 70 }}
                                    height={500}
                                    options={options}
                                    series={options.series}
                                    type={'line'}
                                />
                            </div>
                            <div className='col-xl-3'>
                                <div className='d-flex flex-row justify-content-end'>
                                    <div>
                                        <Button style={{ marginRight: 30 }} size={'lg'} color='light' icon={'Person'} onClick={() => { }}>{user.me.name}</Button>
                                    </div>
                                    <div>
                                        <Button size={'lg'} color='light' icon={'Settings'} onClick={() => { }}></Button>
                                    </div>
                                </div>
                                <Card className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #ffae1f', marginTop: 10 }}>
                                    <CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#fef5e5', color: '#ffae1f' }}>
                                        <CardLabel>
                                            <CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{'단말기 원력 제어'}</CardTitle>
                                            <CardSubTitle className='mt-3' style={{ fontSize: '2.8rem', marginTop: 20 }}>{monthlySum}</CardSubTitle>
                                        </CardLabel>
                                    </CardBody>
                                </Card>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <ArDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} status={status} getDevice={getDevice} />
        </PageWrapper>
    );
});

export default MainPage;