import React, { useEffect, useState } from 'react';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import ReactApexChart from 'react-apexcharts';
import COLORS from '../../../common/data/enumColors';
import DeviceService from '../../../services/DeviceService';
import { useMst } from '../../../models'

const DonutChart = () => {
	const [deviceCount, setDeviceCount] = useState([])
	const [userCount, setUserCount] = useState([])
	const [groupCount, setGroupCount] = useState([])
	const [errorCount, setErrorCount] = useState([])

	const [deviceTotal, setDeviceTotal] = useState(0)
	const [userTotal, setUserTotal] = useState(0)
	const [groupTotal, setGroupTotal] = useState(0)

	const [arTotal, setArTotal] = useState(0)
	const [nrTotal, setNrTotal] = useState(0)
	const [srTotal, setSrTotal] = useState(0)
	const [wrTotal, setWrTotal] = useState(0)

	const [arNomal, setArNomal] = useState(0)
	const [nrNomal, setNrNomal] = useState(0)
	const [srNomal, setSrNomal] = useState(0)
	const [wrNomal, setWrNomal] = useState(0)

	const [arErrors, setArErrors] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
	const errorCode = ['BAT Low(b\'d)', '침수', '누수', '독거', '동파', '고온', '검침기 연결', '검침기 통신',
		'Data Limit', 'Water 오염', '유량 과부하', '역류', '옥내 누수', 'BAT Low(W_meter)', '정상']

	const { user } = useMst()

	const chartOptions = {
		colors: [
			'#8BC1F7',
			'#BDE2B9',
			'#B2B0EA',
			'#F9E0A2',
			'#F4B678',
			'#C9190B',
			'#F0F0F0',
			'#06C',
			'#4CB140',
			'#009596',
			'#5752D1',
			'#F4C145',
			'#EC7A08',
			'#7D1007',
			'#B8BBBE',
			'#002F5D',
			'#23511E',
			'#003737',
			'#2A265F',
			'#C58C00',
			'#8F4700',
			'#2C0000',
			'#6A6E73',
		],
		chart: {
			type: 'donut',
			height: 250,
		},
		stroke: {
			width: 0,
		},
		labels: errorCode,
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				expandOnClick: true,
				donut: {
					labels: {
						show: true,
						name: {
							show: true,
							fontSize: '24px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 0,
							formatter(val) {
								return val;
							},
						},
						value: {
							show: true,
							fontSize: '16px',
							fontFamily: 'Poppins',
							fontWeight: 900,
							offsetY: 16,
							formatter(val) {
								return val;
							},
						},
					},
				},
			},
		},
		legend: {
			show: false,
			position: 'bottom',
		},
	};

	const [DUMMY_DATA, setDUMMY_DATA] = useState({
		AR: {
			series: arErrors,
			options: {
				...chartOptions,
			},
		},
		NR: {
			series: arErrors,
			options: {
				...chartOptions,
			},
		},
		SR: {
			series: arErrors,
			options: {
				...chartOptions,
			},
		},
		WR: {
			series: arErrors,
			options: {
				...chartOptions,
			},
		},
	})

	// const [state, setState] = useState({
	// 	series: DUMMY_DATA.ALL.series,
	// 	options: chartOptions,
	// });

	const SALE_PER_TAB = {
		ALL: 'all',
		FOTA: 'fota',
	};
	const [activeSalePerTab, setActiveSalePerTab] = useState(SALE_PER_TAB.ALL);


	const op_code = {
		AR: '수도검침',
		NR: '층간소음',
		WR: '수질관리',
		SR: '스마트팜',
		XR: '미지정',
	}

	useEffect(() => {
		DeviceService.getDeviceDashboard().then(r => {
			if (user.me.type !== 'SUPERADMIN') {
				setDeviceCount(r.data.device.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setUserCount(r.data.users.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setGroupCount(r.data.group.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
				setErrorCount(r.data.error.filter(d => user.me.model === 'AR' 
					? d.op_code === user.me.model || d.op_code === 'ER' : d.op_code === user.me.model))
			} else {
				setDeviceCount(r.data.device)
				setUserCount(r.data.users)
				setGroupCount(r.data.group)
				setErrorCount(r.data.error)
			}
		})

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	console.log(user.me.type)

	const errorCheck = (error) => {
		let result = []
		if (error) {
			error.split("").reverse().map((e, i) => {
				if (e === '1') {
					result.push(i)
				}
			})
		}

		return result
	}

	useEffect(() => {
		deviceCount.map(d => setDeviceTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [deviceCount])

	useEffect(() => {
		userCount.map(d => setUserTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [userCount])

	useEffect(() => {
		groupCount.map(d => setGroupTotal(dt => parseInt(dt) + parseInt(d.count)))
	}, [groupCount])

	useEffect(() => {
		let arError = []
		let nrError = []
		let srError = []
		let wrError = []
		let xrError = []

		let arTotal = 0
		let nrTotal = 0
		let srTotal = 0
		let wrTotal = 0

		let arNomal = 0
		let nrNomal = 0
		let srNomal = 0
		let wrNomal = 0

		errorCount.map(e => {
			if (!e?.error || e.error === '00000000000000000000000000000000') {
				if (e.op_code === 'AR' || e.op_code === 'ER') {
					arNomal++
				}
				if (e.op_code === 'NR') {
					nrNomal++
				}
				if (e.op_code === 'SR') {
					srNomal++
				}
				if (e.op_code === 'WR') {
					wrNomal++
				}
			} else {
				const erchk = errorCheck(e.error)
				if (e.op_code === 'AR' || e.op_code === 'ER') {
					erchk.map(er => {
						if (!arError[er]) arError[er] = 0
						arError[er]++
						arTotal++
					})
				}
				if (e.op_code === 'NR') {
					erchk.map(er => {
						if (!nrError[er]) nrError[er] = 0
						nrError[er]++
						nrTotal++
					})
				}
				if (e.op_code === 'SR') {
					erchk.map(er => {
						if (!srError[er]) srError[er] = 0
						srError[er]++
						srTotal++
					})
				}
				if (e.op_code === 'WR') {
					erchk.map(er => {
						if (!wrError[er]) wrError[er] = 0
						wrError[er]++
						wrTotal++
					})
				}
			}
		})

		let arSeries = []
		let nrSeries = []
		let srSeries = []
		let wrSeries = []
		for (let i = 0; i < errorCode.length; i ++) {
			arSeries.push(arError[i] || 0)
			nrSeries.push(nrError[i] || 0)
			srSeries.push(srError[i] || 0)
			wrSeries.push(wrError[i] || 0)
		}

		setArTotal(arTotal)
		setSrTotal(srTotal)
		setNrTotal(nrTotal)
		setWrTotal(wrTotal)

		setArNomal(arNomal)
		setSrNomal(srNomal)
		setNrNomal(nrNomal)
		setWrNomal(wrNomal)

		setDUMMY_DATA({
			AR: {
				series: arSeries,
				options: {
					...chartOptions,
				},
			},
			NR: {
				series: nrSeries,
				options: {
					...chartOptions,
				},
			},
			SR: {
				series: srSeries,
				options: {
					...chartOptions,
				},
			},
			WR: {
				series: wrSeries,
				options: {
					...chartOptions,
				},
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorCount])

	useEffect(() => {
		console.log(DUMMY_DATA)
	}, [DUMMY_DATA])
	
	return (
		<Card stretch>
			{/* <CardHeader>
				<CardActions>
					<Button
						color='info'
						onClick={() => {
							setActiveSalePerTab(SALE_PER_TAB.ALL);
							// setState({
							// 	series: DUMMY_DATA.DAY.series,
							// 	options: DUMMY_DATA.DAY.options,
							// });
						}}
						isLink={activeSalePerTab !== SALE_PER_TAB.ALL}
						isLight={activeSalePerTab === SALE_PER_TAB.ALL}>
						전체
					</Button>
					<Button
						color='info'
						onClick={() => {
							setActiveSalePerTab(SALE_PER_TAB.FOTA);
							// setState({
							// 	series: DUMMY_DATA.WEEK.series,
							// 	options: DUMMY_DATA.WEEK.options,
							// });
						}}
						isLink={activeSalePerTab !== SALE_PER_TAB.FOTA}
						isLight={activeSalePerTab === SALE_PER_TAB.FOTA}>
						FOTA
					</Button>
				</CardActions>
			</CardHeader> */}
			<CardBody>
				<div className='row' style={{ justifyContent: "space-between" }}>
					{deviceCount?.length > 0 &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>전체 단말기 수 : {deviceTotal} 개</div>
						{
							deviceCount.map((d, i) => (
								<div key={d.op_code}>● {op_code[d.op_code]} : {parseInt(d.count).toLocaleString()}개</div>
							))
						}
					</div>}
					{userCount?.length > 0 &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>전체 사용자 수 : {userTotal} 개</div>
						{
							userCount.map((u, i) => (
								<div key={u.model}>● {op_code[u.model]} : {parseInt(u.count).toLocaleString()}개</div>
							))
						}
					</div>}
					{groupCount?.length > 0 &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>전체 그룹 수 : {groupTotal} 개</div>
						{
								groupCount.map((u, i) => (
								<div key={u.model}>● {op_code[u.model]} : {parseInt(u.count).toLocaleString()}개</div>
							))
						}
					</div>}
				</div>
				<div className='row' style={{ justifyContent: "space-between" }}>
					{(user.me.model === 'NR' || user.me.type === 'SUPERADMIN') &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>층간소음</div>
							<div>정상: {nrNomal.toLocaleString()}개 / 경고 : {nrTotal.toLocaleString()}개</div>
					</div>}
					{(user.me.model === 'SR' || user.me.type === 'SUPERADMIN') &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>스마트팜</div>
							<div>정상: {srNomal.toLocaleString()}개 / 경고 : {srTotal.toLocaleString()}개</div>
						</div>}
					{(user.me.model === 'WR' || user.me.type === 'SUPERADMIN') &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>수질관리</div>
							<div>정상: {wrNomal.toLocaleString()}개 / 경고 : {wrTotal.toLocaleString()}개</div>
						</div>}
					{(user.me.model === 'AR' || user.me.type === 'SUPERADMIN') &&
					<div style={{ width: 250, textAlign: "center", marginTop: 60, marginBottom: 20 }}>
						<div>수도검침</div>
							<div>정상: {arNomal.toLocaleString()}개 / 경고 : {arTotal.toLocaleString()}개</div>
					</div>}
				</div>
				<div className='row' style={{justifyContent: "space-between"}}>
					{(user.me.model === 'NR' || user.me.type === 'SUPERADMIN') &&
						<div style={{ width: 250 }}>
							<ReactApexChart
								options={DUMMY_DATA.NR.options}
								series={DUMMY_DATA.NR.series}
								width={250}
								type={DUMMY_DATA.NR.options.chart.type}
								height={DUMMY_DATA.NR.options.chart.height}
							/>
						</div>}
					{(user.me.model === 'SR' || user.me.type === 'SUPERADMIN') &&
						<div style={{ width: 250 }}>
							<ReactApexChart
								options={DUMMY_DATA.SR.options}
								series={DUMMY_DATA.SR.series}
								width={250}
								type={DUMMY_DATA.SR.options.chart.type}
								height={DUMMY_DATA.SR.options.chart.height}
							/>
						</div>}
					{(user.me.model === 'WR' || user.me.type === 'SUPERADMIN') &&
						<div style={{ width: 250 }}>
							<ReactApexChart
								options={DUMMY_DATA.WR.options}
								series={DUMMY_DATA.WR.series}
								width={250}
								type={DUMMY_DATA.WR.options.chart.type}
								height={DUMMY_DATA.WR.options.chart.height}
							/>
						</div>}
					{(user.me.model === 'AR' || user.me.type === 'SUPERADMIN') &&
					<div style={{width: 250}}>
						<ReactApexChart
							options={DUMMY_DATA.AR.options}
							series={DUMMY_DATA.AR.series}
							width={250}
							type={DUMMY_DATA.AR.options.chart.type}
							height={DUMMY_DATA.AR.options.chart.height}
						/>
					</div>}
				</div>
			</CardBody>
		</Card>
	);
};

export default DonutChart;
