import { APIClient } from '../lib/apiHelper';

const { get, post, put } = new APIClient();

export default {
	/**
	 * 이메일 존재 여부 확인
	 * @method GET
	 * @param {STRING} email
	 */
	isExistEmail: (email) => get(`/user/is-exist-email?email=${email}`),
	isExistUserId: (userId) => get(`/user/is-exist-userId?userId=${userId}`),
	/**
	 * 회원 가입
	 * @method POST
	 * @param {OBJECT} user
	 * =========================
	 * @param {STRING} email
	 * @param {STRING} password
	 * @param {STRING} name
	 */
	joinus: (user) => post('/user/joinus', user),
	modify: (user) => post('/user/modify', user),
	/**
	 * 로그인
	 * @method POST
	 * @param {STRING} email
	 * @param {STRING} password
	 */
	login: (user) => post('/user/login', user),
	logout: (user) => put('/user/logout'),
	/**
	 * 내정보 가져 오기
	 * @method GET
	 * no-params
	 */
	me: () => get('/user/me'),
	/**
	 * 비밀번호 찾기 이메일 인증
	 * @method GET
	 * @param {STRING} email
	 * @param {STRING} password
	 */
	changePassword: async (email, password) => {
		const dummy = password.split('').reverse().join('');
		const passwordData = { email, password, dummy };

		return put('/user/change-password', passwordData);
	},
	getUserList: (data) => post('/user/list', data),
	grouping: (data) => post('/user/grouping', data),
	exitgroup: (data) => post('/user/exitgroup', data),
	getSystemSetting: () => get('/system/setting'),
	saveSystemSetting: (data) => post('/system/setting', data),
};
