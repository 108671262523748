const LANG = {
	EN: {
		text: 'English',
		lng: 'en',
		icon: 'CustomUsa',
	}, 
	KO: {
		text: '한국어',
		lng: 'ko',
		icon: 'CustomKor',
	},	
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
