import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../components/bootstrap/Button";
import Modal, { ModalBody, ModalHeader } from "../../components/bootstrap/Modal";

const MapModal = observer(() => {
		const [isOpenMap, setIsOpenMap] = useState(false);

    const handleClose = () => {
        setIsMapModalOpen(false);
    };

	useEffect(() => {
		if (isOpenMap) {
			getMaps(37.205, 127.106, 3)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpenMap]);

    const getMaps = useCallback((latitude, longitude, radius) => {
        kakao.maps.load(async () => {
            // console.log('get map');
            let container = document.getElementById('kakaoMap');
            let options = {
                center: new kakao.maps.LatLng(latitude, longitude),
                level: 5,
            };

            const map = new window.kakao.maps.Map(container, options);

            // 위성지도 설정
            map.setMapTypeId(window.kakao.maps.MapTypeId.HYBRID);

            const greenMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png', // 초록색 마커 이미지 URL
                new window.kakao.maps.Size(24, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );

            const redMarkerImage = new window.kakao.maps.MarkerImage(
                'http://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 빨간색 마커 이미지 URL
                new window.kakao.maps.Size(30, 35), // 이미지 크기
                {
                    offset: new window.kakao.maps.Point(12, 35), // 이미지의 중앙 하단이 앵커가 되도록 설정
                }
            );


            const center = { lat: 37.205, lng: 127.106 };
            const range = 0.02; // ±0.01 정도의 범위
            const numPoints = 10;

            const randomCoordinates = generateRandomCoordinates(center, range, numPoints);

            randomCoordinates.forEach((coordinate, i) => {
                const marker = new kakao.maps.Marker({
                    position: new kakao.maps.LatLng(coordinate.lat, coordinate.lng),
                    image: i % 2 === 0 ? greenMarkerImage : redMarkerImage,
                });
                marker.setMap(map);
            });

            // const circle = new kakao.maps.Circle({
            // 	center: new kakao.maps.LatLng(latitude, longitude), // 원의 중심좌표 입니다
            // 	radius: radius, // 미터 단위의 원의 반지름입니다
            // 	strokeWeight: 1, // 선의 두께입니다
            // 	strokeColor: '#75B8FA', // 선의 색깔입니다
            // 	strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
            // 	strokeStyle: 'solid', // 선의 스타일 입니다
            // 	fillColor: '#CFE7FF', // 채우기 색깔입니다
            // 	fillOpacity: 0.7, // 채우기 불투명도 입니다
            // });

            // circle.setMap(map);
        });
    }, []);

    return (
			<>
				<Button color='light' icon='Map'
					onClick={() => {
						setIsOpenMap(true)
					}}>
					{''}
				</Button>
				<Modal
					setIsOpen={setIsOpenMap}
					isOpen={isOpenMap}
					isStaticBackdrop={true}
					isScrollable={true}
					isCentered={true}
					//fullScreen={true}
					fullScreen={'lg'}
					size={'xl'}>
					<ModalHeader className='border-top d-flex justify-content-between'>
						<div className={'text-dark'} style={{ fontSize: '1.5rem' }}>
							<b>수용가 위치</b>
						</div>
						<div>
							<ButtonGroup>
								<Button
									type='button'
									color='dark'
									icon='Close'
									onClick={() => {
										setIsOpenMap(false);
									}}>
									{''}
								</Button>
							</ButtonGroup>
						</div>
					</ModalHeader>
					<ModalBody>
						<div
							className='position-relative border w-100 d-inline-block bg-l25-light border'
							style={{
								width: '100%',
								height: '80vh',
							}}>
							<div id='kakaoMap' className='kakao-map' style={{ width: '100%', height: '80vh' }}></div>
						</div>
					</ModalBody>
				</Modal>
			</>
    );
});

export default MapModal;