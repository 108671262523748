import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card, { CardActions, CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import useRequest from '../../../hooks/useRequest';
import { useMst } from '../../../models';
import showNotification from '../../../components/extras/showNotification';
import Badge from '../../../components/bootstrap/Badge';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import * as locales from 'react-date-range/dist/locale';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';

const UserWeeklyAttendHistoryCard = observer(() => {
	const navigate = useNavigate();
	const { t } = useTranslation(['translation', 'menu']);
	const { user, company } = useMst();
	const ref = useRef();
	const [selectedDateRange, setSelectedDateRange] = useState([
		{
			startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
			endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
			key: 'selection',
		},
	]);
	const [tempSelectedDateRange, setTempSelectedDateRange] = useState([
		{
			startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
			endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
			key: 'selection',
		},
	]);
	const { responseData: logData, loading: logLoading, request: logRequest } = useRequest([]);

	const requestSort = useCallback((a, b) => {
		const existYaxisInA = 'yaxis' in a;
		const existYaxisInB = 'yaxis' in b;
		//정렬할 때 yaix가 있으면 해당 셀은 고정되어야 함.
		if (existYaxisInA && existYaxisInB) {
			return a.yaxis < b.yaxis ? -1 : a.yaxis > b.yaxis ? 1 : 0;
		} else if ((existYaxisInA && !existYaxisInB) || (!existYaxisInA && existYaxisInB)) {
			return 0;
		} else {
			return a.duration < b.duration
				? 1
				: a.duration < b.duration
				? -1
				: moment(a.start) < moment(b.start)
				? -1
				: moment(a.start) > moment(b.start)
				? 1
				: moment(a.end) < moment(b.end)
				? 1
				: moment(a.end) > moment(b.end)
				? -1
				: 0;
		}
	}, []);

	const requestAllData = useCallback(async () => {
		await logRequest(
			AttendService.getLogAttend,
			[0, selectedDateRange[0].startDate, selectedDateRange[0].endDate, company.get.id, 'day', user.me.id],
			(res) => {
				if (res?.data) {
					let result;
					const initDateList = [0, 1, 2, 3, 4, 5, 6].map((index) => ({
						date: moment(selectedDateRange[0].startDate).add(index, 'days'),
						specialDay: [],
						data: [],
					}));

					const newList = res?.data?.[0]?.reduce((all, cur) => {
						if (!cur?.start) return all;
						const dateIndexList = [];

						all.forEach((dateObj, index) => {
							if (
								moment(dateObj.date).format('YYYY-MM-DD') >= moment(cur.start).format('YYYY-MM-DD') &&
								moment(dateObj.date).format('YYYY-MM-DD') <= moment(cur.end).format('YYYY-MM-DD')
							) {
								dateIndexList.push(index);
							}
						});

						if (dateIndexList.length > 0) {
							dateIndexList.forEach((dateIndex) => {
								cur['duration'] = moment(moment(cur.end).format('YYYY-MM-DD')).diff(
									moment(moment(cur.start).format('YYYY-MM-DD')),
									'days'
								);
								cur['id'] = uuid();
								if ('memo' in cur) {
									all[dateIndex].specialDay.push(cur);
								} else {
									all[dateIndex].data.push(cur);
								}
							});
						}
						return all;
					}, initDateList);

					let prevObjForCheckIsSameObj = null;
					result = newList.map((newListDateObj, newListIndex) => {
						let copyNewListObj = { ...newListDateObj };

						let insertedYaxis = null;
						insertedYaxis = copyNewListObj.data.map((d, i) => {
							if (newListIndex === 0) {
								copyNewListObj.data.sort(requestSort);
								return { ...d, yaxis: i };
							} else {
								const isEx = prevObjForCheckIsSameObj?.data.find((o) => o.id === d.id) || false;
								if (isEx) {
									return { ...d, yaxis: isEx.yaxis };
								}
								return d;
							}
						});
						copyNewListObj = {
							...copyNewListObj,
							data: insertedYaxis,
						};

						const yaxisIndexList = copyNewListObj.data.reduce((all, cur, i) => {
							if ('yaxis' in cur) {
								all.push(i);
							}
							return all;
						}, []);
						const swap = (arr, targetIndex, comparisonTargetIndex) => {
							[arr[targetIndex], arr[comparisonTargetIndex]] = [arr[comparisonTargetIndex], arr[targetIndex]];
						};
						if (copyNewListObj.data.length > 1) {
							for (let i = 0; i < copyNewListObj.data.length; i++) {
								if (yaxisIndexList.includes(i)) continue;
								let target = copyNewListObj.data[i];
								for (let j = i; j < copyNewListObj.data.length; j++) {
									let comparisonTarget = copyNewListObj.data[j + 1];
									if (comparisonTarget === undefined || 'yaxis' in comparisonTarget) continue;
									if (target.duration < comparisonTarget.duration) swap(copyNewListObj.data, i, j + 1);
									else if (target.duration > comparisonTarget.duration) continue;
									else {
										if (moment(target.start) < moment(comparisonTarget.start)) continue;
										else if (moment(target.start) > moment(comparisonTarget.start)) swap(copyNewListObj.data, i, j + 1);
										else {
											if (moment(target.end) < moment(comparisonTarget.end)) continue;
											else if (moment(target.end) > moment(comparisonTarget.end)) swap(copyNewListObj.data, i, j + 1);
											else continue;
										}
									}
								}
							}
						}
						//정렬되어있는 것들에게 순서대로 yaxis삽입하는 것이므로 미리 정렬이 되어 있어야 함
						copyNewListObj.data.map((d, i) => {
							if (!('yaxis' in d)) {
								const yaxisList = copyNewListObj.data
									.filter((o) => 'yaxis' in o)
									.sort((a, b) => (a.yaxis < b.yaxis ? -1 : a.yaxis > b.yaxis ? 1 : 0));
								copyNewListObj.data[i]['yaxis'] = yaxisList.reduce((all, cur) => {
									if (all === cur.yaxis) {
										return all + 1;
									}
									return all;
								}, 0);
							}
						});
						copyNewListObj.data.sort(requestSort);

						let insertedDummy = [];
						copyNewListObj.data.map((d, i) => {
							if (i === 0) {
								if (i < d.yaxis) {
									for (let j = 0; j < d.yaxis; j++) {
										insertedDummy.push({ isDummy: true, yaxis: j });
									}
								}
								insertedDummy.push(d);
							}
							if (i !== 0) {
								const diffYaxis = Math.abs(d.yaxis - copyNewListObj.data[i - 1].yaxis);
								if (diffYaxis > 1) {
									for (let j = 0; j < diffYaxis; j++) {
										insertedDummy.push({ isDummy: true, yaxis: j + i });
									}
								}
								insertedDummy.push(d);
							}
						});
						copyNewListObj = {
							...copyNewListObj,
							data: insertedDummy,
						};

						prevObjForCheckIsSameObj = copyNewListObj;
						return copyNewListObj;
					});

					return { data: result };
				}
			},
			(error) => {
				showNotification(t('주간 근무 현황'), t('주간근무 현황을 불러오는 도중에 문제가 발생했습니다.'), 'danger');
			}
		);
	}, [user, company, selectedDateRange, requestSort, logRequest, t]);

	const calendarBadgeClickEvent = (data) => {
		navigate(usersMenu.WorkApproval.path, { state: { dashboardSelectedData: data } });
	};

	const convertLogToText = (logData, cureentDate, index) => {
		const commonClass = 'mt-1 d-block text-wrap cursor-pointer';
		const starttime = logData?.start || null;
		const endtime = logData?.end || null;
		const stimereal = logData?.stimeReal || null;
		const etimereal = logData?.etimeReal || null;
		const isTardy = logData?.attend?.tardy || null;
		const logMemo = logData?.memo ? logData?.memo : null;
		const logValue = logData?.value ? logData?.value : null;
		const isDummy = logData?.isDummy || false;
		let renderBadge = <></>;
		if (isDummy) {
			renderBadge = (
				<Badge key={index} color='light' className='invisible'>
					dummy
				</Badge>
			);
		}
		if (!isDummy && (logValue || logMemo)) {
			if (!starttime && !endtime) {
				renderBadge = <></>;
			} else if (starttime && endtime) {
				if (logData.duration > 0) {
					if (moment(cureentDate).format('YYYY-MM-DD') === moment(starttime).format('YYYY-MM-DD')) {
						renderBadge = (
							<Badge
								key={index}
								className={`ms-1 ${commonClass} text-dark ${
									logData?.request?.type === 'REST' ? 'bg-l25-secondary' : 'bg-l25-success'
								}`}
								color={logData?.request?.type === 'REST' ? 'secondary' : 'success'}
								rounded='start'
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${t(logValue)} ${t('시작')}`}</Badge>
						);
					} else if (
						moment(cureentDate).isBetween(starttime, endtime) &&
						moment(cureentDate).format('YYYY-MM-DD') !== moment(endtime).format('YYYY-MM-DD')
					) {
						renderBadge = (
							<Badge
								key={index}
								className={`${commonClass} text-dark ${
									logData?.request?.type === 'REST' ? 'bg-l25-secondary' : 'bg-l25-success'
								}`}
								color={logData?.request?.type === 'REST' ? 'secondary' : 'success'}
								rounded={0}
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${t(t(logValue))}`}</Badge>
						);
					} else {
						renderBadge = (
							<Badge
								key={index}
								className={`me-1 ${commonClass} text-dark ${
									logData?.request?.type === 'REST' ? 'bg-l25-secondary' : 'bg-l25-success'
								}`}
								color={logData?.request?.type === 'REST' ? 'secondary' : 'success'}
								rounded='end'
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${t(logValue)} ${t('끝')}`}</Badge>
						);
					}
				} else {
					if (moment(starttime).isSame(moment(endtime))) {
						renderBadge = (
							<Badge
								key={index}
								className={`mx-1 ${commonClass} text-dark ${
									logData?.request?.type === 'REST' ? 'bg-l25-secondary' : 'bg-l25-success'
								}`}
								color={logData?.request?.type === 'REST' ? 'secondary' : 'success'}
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${t(
								logValue && !logMemo ? t(logValue) : !logValue && logMemo ? t(logMemo) : ''
							)}`}</Badge>
						);
					} else {
						renderBadge = (
							<Badge
								key={index}
								className={`mx-1 ${commonClass} text-dark ${
									logData?.request?.type === 'REST' ? 'bg-l25-secondary' : 'bg-l25-success'
								}`}
								color={logData?.request?.type === 'REST' ? 'secondary' : 'success'}
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${moment(starttime).format('A hh:mm')} ~ ${moment(
								endtime
							).format('A hh:mm')} | ${t(logValue)}`}</Badge>
						);
					}
				}
			} else {
				renderBadge = (
					<Badge key={index} className={commonClass} color='danger'>
						{t('데이터 형식 에러')}
					</Badge>
				);
			}
		}

		if (!isDummy && !logValue && !logMemo) {
			if (!stimereal && !etimereal) {
				renderBadge = <></>;
			} else if (stimereal && !etimereal) {
				renderBadge = (
					<Badge
						key={index}
						className={`mx-1 ${commonClass} ${isTardy ? 'text-danger' : 'text-dark'}`}
						color='light'
						onClick={(e) => calendarBadgeClickEvent(logData)}>{`${isTardy ? `${t('지각')} / ` : ''}${moment(
						stimereal
					).format('A hh:mm')} ~ ${t('미체크')}`}</Badge>
				);
			} else if (stimereal && etimereal) {
				if (logData.duration > 0) {
					if (moment(cureentDate).format('YYYY-MM-DD') === moment(stimereal).format('YYYY-MM-DD')) {
						renderBadge = (
							<Badge
								key={index}
								className={`ms-1 ${commonClass} ${isTardy ? 'text-danger' : 'text-dark'}`}
								color='light'
								rounded='start'
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${isTardy ? t('지각') : t('출근')} / ${moment(
								stimereal
							).format('A hh:mm')}`}</Badge>
						);
					} else if (
						moment(cureentDate).isBetween(stimereal, etimereal) &&
						moment(cureentDate).format('YYYY-MM-DD') !== moment(etimereal).format('YYYY-MM-DD')
					) {
						renderBadge = (
							<Badge
								key={index}
								className={`${commonClass} ${isTardy ? 'text-danger' : 'text-dark'}`}
								color='light'
								rounded={0}
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${t('근무')}`}</Badge>
						);
					} else {
						renderBadge = (
							<Badge
								key={index}
								className={`me-1 ${commonClass} ${isTardy ? 'text-danger' : 'text-dark'}`}
								color='light'
								rounded='end'
								onClick={(e) => calendarBadgeClickEvent(logData)}>{`${moment(etimereal).format('A hh:mm')} / ${t(
								'퇴근'
							)}`}</Badge>
						);
					}
				} else {
					renderBadge = (
						<Badge
							key={index}
							className={`mx-1 ${commonClass} ${isTardy ? 'text-danger' : 'text-dark'}`}
							color='light'
							onClick={(e) => calendarBadgeClickEvent(logData)}>{`${isTardy ? `${t('지각')} / ` : ''}${moment(
							stimereal
						).format('A hh:mm')} ~ ${moment(etimereal).format('A hh:mm')}`}</Badge>
					);
				}
			} else {
				renderBadge = (
					<Badge key={index} className={commonClass} color='danger'>
						{t('데이터 형식 에러')}
					</Badge>
				);
			}
		}

		return renderBadge;
	};

	useEffect(() => {
		requestAllData();
	}, [requestAllData]);

	return (
		<Card stretch>
			<CardHeader>
				<CardTitle className={'cursor-pointer'} onClick={() => navigate(usersMenu.WorkApproval.path)}>
					{t('주간 근무 현황')}
				</CardTitle>
				<CardActions>
					<ButtonGroup size='sm'>
						<Button
							color='light'
							icon='ArrowBackIos'
							isDisable={logLoading}
							onClick={() =>
								setSelectedDateRange((prev) => {
									let prevDate = moment(prev[0].startDate).subtract(1, 'weeks');
									return [
										{
											...prev,
											startDate: moment(prevDate).startOf('isoWeek').format('YYYY-MM-DD'),
											endDate: moment(prevDate).endOf('isoWeek').format('YYYY-MM-DD'),
										},
									];
								})
							}></Button>
						<Popovers
							id='selectDate'
							placement={'bottom'}
							trigger='click'
							desc={
								<Card className='shadow-none w-100 mb-0'>
									<CardBody className='px-0 pb-0 row'>
										<DateRange
											direction='horizontal'
											locale={locales['ko']}
											dateDisplayFormat='yyyy-MM-dd'
											dragSelectionEnabled={false}
											showDateDisplay={false}
											focusedRange={[0, 0]}
											ranges={[
												{
													key: 'selection',
													startDate: moment(selectedDateRange[0].startDate).toDate(),
													endDate: moment(selectedDateRange[0].endDate).toDate(),
												},
											]}
											/* preview={{
												key: 'selection',
												startDate: moment(tempSelectedDateRange[0].startDate).toDate(),
												endDate: moment(tempSelectedDateRange[0].endDate).toDate(),
											}}
											onPreviewChange={(date) => {
												if (!date) return;
												setTempSelectedDateRange([
													{
														key: 'selection',
														startDate: moment(date).startOf('isoWeek').format('YYYY-MM-DD'),
														endDate: moment(date).endOf('isoWeek').format('YYYY-MM-DD'),
													},
												]);
											}} */
											onChange={(item) => {
												if (logLoading) return;
												setSelectedDateRange((prev) => {
													if (
														moment(prev[0].startDate) > moment(item.selection.endDate) ||
														moment(prev[0].endDate) < moment(item.selection.endDate)
													) {
														return [
															{
																key: 'selection',
																startDate: moment(item.selection.endDate).startOf('isoWeek').format('YYYY-MM-DD'),
																endDate: moment(item.selection.endDate).endOf('isoWeek').format('YYYY-MM-DD'),
															},
														];
													}
													return prev;
												});
											}}
										/>
									</CardBody>
								</Card>
							}>
							<Button color='light' isDisable={logLoading}>
								{`${moment(selectedDateRange[0].startDate).format('L(ddd)')} ~ ${moment(
									selectedDateRange[0].endDate
								).format('L(ddd)')}`}
							</Button>
						</Popovers>
						<Button
							color='light'
							icon='ArrowForwardIos'
							isDisable={logLoading}
							onClick={() =>
								setSelectedDateRange((prev) => {
									let prevDate = moment(prev[0].startDate).add(1, 'weeks');
									return [
										{
											...prev,
											startDate: moment(prevDate).startOf('isoWeek').format('YYYY-MM-DD'),
											endDate: moment(prevDate).endOf('isoWeek').format('YYYY-MM-DD'),
										},
									];
								})
							}></Button>
					</ButtonGroup>
				</CardActions>
			</CardHeader>
			<CardBody
				className='dashboard-height overflow-auto'
				ref={ref}
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{logLoading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) : !logLoading && logData?.length <= 0 ? (
					<div className='text-center py-5 lead'>{t('주간 근무 현황이 비어있습니다.')}</div>
				) : !logLoading && logData?.length > 0 ? (
					<div className='h-100 row col-xl-12 m-0'>
						{logData.map((log, index) => (
							<Card key={`logData-${index}`} className='col-4 m-0 p-0' shadow='none' borderSize={1}>
								<CardBody className='p-0'>
									<div className={`p-1 ${index >= 5 ? 'text-danger' : 'text-dark'} d-flex justify-content-between`}>
										<div>{moment(log?.date).format('MM-DD dddd')}</div>
										<div>
											{log?.specialDay?.map((d, i) => (
												<Badge key={`memo-${i}`} color='danger' className='me-1'>
													{d.memo || ''}
												</Badge>
											))}
										</div>
									</div>
									<div>
										{log?.data.length <= 0 ? (
											<></>
										) : (
											log.data.map((logData, index) => convertLogToText(logData, log?.date, index))
										)}
									</div>
								</CardBody>
							</Card>
						))}
					</div>
				) : (
					<></>
				)}
			</CardBody>
		</Card>
	);
});
export default React.memo(UserWeeklyAttendHistoryCard);
