import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useMst } from '../../../models';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../../components/bootstrap/Card';
import ReactApexChart from 'react-apexcharts';
import DeviceService from '../../../services/DeviceService';
import HeaderTop from '../HeaderTop';
import Icon from '../../../components/icon/Icon';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import User from '../../../layout/User/User';
import ArDeviceModal from './ArDeviceModal';
import ArUsage from './ArUsage';
import MapModal from '../MapModal';
import { use } from 'i18next';
import PacketService from '../../../services/PacketService';
import ReactSelect from 'react-select';
import { setDate } from 'date-fns';
import moment from 'moment';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { set } from 'lodash';
import { DeviceHub, DeviceThermostat, Home } from '../../../components/icon/material-icons';
import UserService from '../../../services/UserService';

const datas = [
    { label: '온도', type: 'Temperature_In', icon: 'Thermometer', value: 25, unit: '℃'},
    { label: '습도', type: 'Humidity_In', icon: 'Water', value: 50, unit: '%'},
    { label: '지온', type: 'Ground_Temperature', icon: 'Thermometer', value: 25, unit: '℃'},
    { label: '지습', type: 'Ground_Humidity', icon: 'Water', value: 50, unit: '%'},
    { label: 'ph', type: 'pH', icon: 'ph', value: 7, unit: ''},
    { label: 'EC', type: 'EC', icon: 'EC', value: 1.5, unit: 'dS/m'},
    { label: 'CO2', type: 'CO2', icon: 'CO2', value: 500, unit: 'ppm'},
]

const dateTypes = [
    { value: 'daily', label: '일간' },
    { value: 'monthly', label: '월간' },
    { value: 'yearly', label: '연간' },
]

const email = new URL(window.location.href).searchParams.get("userId");
const token = new URL(window.location.href).searchParams.get("token");

const MobilePage = observer(() => {
    const { user, company, notifiction } = useMst();
    const navigate = useNavigate();
    const { t } = useTranslation(['translation', 'menu']);

    
    const [isEditMode, setIsEditMode] = useState(false);
    const [deviceChartData, setDeviceChartData] = useState([]);
    const [favoriteDevice, setFavoriteDevices] = useState([]);
    const [devices, setDevices] = useState([]);
    const [status, setStatus] = useState('정상');
    const [params, setParams] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [viewTab, setViewTab] = useState('MAP');
    const [detailViewDevice, setDetailViewDevice] = useState({});
    const [selectedTab, setSelectedTab] = useState(1);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [bottomIndex, setBottomIndex] = useState(0);

    const getDevice = useCallback(async () => {
        const { data } = await DeviceService.getDeviceList()

        setDevices(data.list)
        setFavoriteDevices(data.list.filter(d => d.favorite))
    }, [])

    useEffect(() => {
        console.log('test', email, token);
        UserService.login({ email, password: token }).then((res) => {
            console.log('login', res);
            if (res.data) {
                user.authMe()
            } else {
                user.logout()
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.me) {
            getDevice()
        }
    }, [user.me, getDevice]);

    // useEffect(() => {
    //     getDevice()
    // }, [getDevice]);

    const getDevicePacket = useCallback(async (device) => {
        if (device.IMEI) {
            params.imei = device.IMEI
            params.perPage = 1000
            params.endDate = moment().format('YYYY-MM-DD')
            params.startDate = moment().subtract(selectedTab, 'days').format('YYYY-MM-DD')
            console.log(params)
            const { data } = await PacketService.getPacketList2(params)
            console.log(data)
        setDeviceChartData(data.list.reverse())
        }
    }, [params, selectedTab])

    useEffect(() => {
        getDevicePacket(detailViewDevice)
    }, [detailViewDevice, getDevicePacket, params, selectedTab]);

    const updateDevices = useCallback((device) => {
        console.log('updateDevices', devices);
        DeviceService.updateDevices({ devices: [device] }).then((res) => {
            console.log('updateDevices', res);
            getDevice()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    useEffect(() => {
        if (search === '') {
            setFavoriteDevices(devices.filter(d => d.favorite));
            return;
        }
        setFavoriteDevices(devices.filter(d => d.favorite).filter((device) => {
            return device.IMEI.includes(search) || device.SerialNumber.includes(search) || device.install.installName.includes(search);
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search]);

    let options = {
        title: {
            enabled: true,
            text: '검침값 추이',
            align: 'left'
        },
        legend: {
            show: true
        },
        series: [{
            name: '검침값',
            data: deviceChartData.map(d => d[datas[selectedIndex].type])
        }],
        labels: selectedTab === 1 ? deviceChartData.map(d => moment(d.updatedAt).format('HH시')) : deviceChartData.map(d => moment(d.updatedAt).format('MM/DD')),

        colors: [
            '#06C',
            '#B8BBBE',
            '#5752D1',
            '#C9190B',
            '#4CB140',
            '#009596',
            '#F4C145',
            '#EC7A08',
            '#7D1007',
            '#002F5D',
            '#23511E',
            '#003737',
            '#2A265F',
            '#C58C00',
            '#8F4700',
            '#2C0000',
            '#6A6E73',
        ],
        chart: {
            title: {
                text: '검침값 추이',
                align: 'left'
            },
            type: 'line',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20
        },
        dataLabels: {
            enabled: true,
            formatter: (value, { dataPointIndex }) => {
                if (value === Math.max(...deviceChartData.map(d => d[datas[selectedIndex].type])) || value === Math.min(...deviceChartData.map(d => d[datas[selectedIndex].type]))) {
                    return value// + ' ' + moment(deviceChartData[dataPointIndex].updatedAt).format('MM/DD HH시');
                } else {
                    return '';
                }
            },
            style: {
                colors: ['#F44336', '#E91E63', '#9C27B0', '#673AB7']
            }
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 1,
            dashArray: 0,
        },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shade: 'light',
        //         type: "vertical",
        //         opacityFrom: 0.4,
        //         opacityTo: 1,
        //         colorStops: []
        //     },
        // },
        // stroke: {
        // 	show: true,
        // 	lineCap: 'butt',
        // 	colors: undefined,
        // 	width: 1,
        // 	dashArray: 0,
        // },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shadeIntensity: 1,
        //         // inverseColors: false,
        //         opacityFrom: 0.5,
        //         opacityTo: 0,
        //         stops: [0, 90, 100]
        //     },
        // },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: '',
                offsetX: 32,
                offsetY: -222,
                rotate: 0,
            },
        },
        xaxis: {
            tickAmount: 6,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
            },
            title: {
                text: ''
            },
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val + ' m<sup>3</sup>'
                }
            }
        }
    };

    const styles = {
        topCard: {
            border: '1px solid #49beff',
            backgroundColor: '#e8f7ff',
            height: viewTab === 'DETAIL' ? '60px' : '98vh', overflowY: viewTab === 'DETAIL' ? 'hidden' : 'auto',
            transition: '.25s all',
            cursor: 'pointer',
        },
    };

    return (
        <>
        { (token !== 'paYLoXfcgv9ZhyoyIYNfXsh4W2Iam+VWpBV7OHXb9qvYQBaCYqU2ofZ96E8ggEDjw4XsfDgNf/m7HNFIih5IWA==' && token !== 'sr1234') && <PageWrapper />}
        { (token === 'paYLoXfcgv9ZhyoyIYNfXsh4W2Iam+VWpBV7OHXb9qvYQBaCYqU2ofZ96E8ggEDjw4XsfDgNf/m7HNFIih5IWA==' || token === 'sr1234') && 
        <div style={{flex: 1, padding:5}}>
            <Card className={`custom-box-shadow rounded-2`} style={styles.topCard}>
                <CardBody className={`rounded-2 align-items-center text-center`}>
                    <div style={{ cursor: 'pointer'}}
                        className='d-flex flex-row justify-content-between'
                     onClick={() => {
                        setDetailViewDevice({})
                        setViewTab('MAP')
                    }}>
                        <div style={{width: 20}}>&nbsp;</div>
                                {detailViewDevice.IMEI && <CardTitle style={{ fontSize: '1.6rem'}}>{detailViewDevice?.install?.installName || '별칭을 설정해주세요'}</CardTitle>}
                                {!detailViewDevice.IMEI && <CardTitle style={{ fontSize: '1.6rem' }}>{user.me.nickname || user.me.name}</CardTitle>}
                        <div style={{ fontSize: '1.2rem', color: '#5d87ff'}}>▼</div>
                    </div>
                    <div style={{overflowY: 'auto'}}>
                        {devices.map((d, i) => (
                            <Card key={i} className={`custom-box-shadow rounded-2`}
                                style={{ border: '1px solid #5d87ff', marginTop: 10, cursor: 'pointer'}}
                                onClick={(e) => {
                                    console.log('click', d);
                                    if (isEditMode) return;
                                    setDetailViewDevice(d)
                                    setViewTab('DETAIL')
                                }}
                            >
                                <CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: '#ecf2ff', color: '#5d87ff' }}>
                                    <div className='d-flex flex-row justify-content-center'>
                                        {isEditMode && 
                                        <>
                                            <Input type='text' placeholder={d?.install?.installName || '별칭을 설정해주세요'}
                                                style={{ borderRadius: 5, height: 35}}
                                                onChange={(e) => {
                                                    d.install.installName = e.target.value;
                                                }} 
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    e.nativeEvent.stopPropagation()
                                                    e.nativeEvent.preventDefault()
                                                }}
                                                />
                                            <Icon icon='Check' fontSize={25} style={{marginTop: 5, marginLeft: 10 }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    e.nativeEvent.stopPropagation()
                                                    e.nativeEvent.preventDefault()
                                                    setIsEditMode(false)
                                                    DeviceService.getInstallModify(d.install)
                                                }}
                                            />
                                        </>}
                                        { !isEditMode && 
                                        <>
                                            <CardTitle style={{ fontSize: '1.5rem', marginTop: 0 }}>{d?.install?.installName || '별칭을 설정해주세요'}</CardTitle>
                                            <Icon icon='Edit' fontSize={25} style={{marginLeft: 10}}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    e.preventDefault()
                                                    e.nativeEvent.stopPropagation()
                                                    e.nativeEvent.preventDefault()
                                                    setIsEditMode(true)
                                                }} 
                                            />
                                        </>}
                                        {/* <div>
                                            <Icon icon='Settings' fontSize={25} />
                                            <Icon icon='Close' fontSize={25} onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                e.nativeEvent.stopPropagation()
                                                e.nativeEvent.preventDefault()
                                                d.favorite = false
                                                updateDevices(d)
                                            }} style={{cursor:'pointer'}} />
                                        </div> */}
                                    </div>
                                    <div className='w-100 text-left' style={{ marginTop: 0 }}>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div style={{ width: '100%' }}>IMEI: {d?.IMEI}</div>
                                            <div style={{ width: '100%' }}>S/N: {d?.SerialNumber}</div>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div style={{ width: '100%' }}>Model: {d?.Model}</div>
                                            <div style={{ width: '100%' }}>버전: {d?.FW_Version}</div>
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            {(d.packets.length > 0 && d.packets[0]?.Temperature_In) && <div style={{ width: '100%' }}> 온도: {d.packets[0]?.Temperature_In}℃</div>}
                                            {(d.packets.length > 0 && d.packets[0]?.Humidity_In) && <div style={{ width: '100%' }}> 습도: {d.packets[0]?.Humidity_In}%</div>}
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            {(d.packets.length > 0 && d.packets[0]?.Ground_Temperature ) && <div style={{ width: '100%' }}>지온: {d.packets[0]?.Ground_Temperature}℃</div>}
                                            {(d.packets.length > 0 && d.packets[0]?.Ground_Humidity) &&  <div style={{ width: '100%' }}>지습: {d.packets[0]?.Ground_Humidity}%</div>}
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            {(d.packets.length > 0 && d.packets[0]?.pH) && <div style={{ width: '100%' }}>ph: {d.packets[0]?.pH}</div>}
                                            {(d.packets.length > 0 && d.packets[0]?.EC) && <div style={{ width: '100%' }}>EC: {d.packets[0]?.EC}dS/m</div>}
                                        </div>
                                        <div className='d-flex flex-row justify-content-between'>
                                            {(d.packets.length > 0 && d.packets[0]?.BAT) && <div style={{ width: '100%' }}>배터리: {d.packets[0]?.BAT}%</div>}
                                            {(d.packets.length > 0 && d.packets[0]?.CO2) && <div style={{ width: '100%' }}>CO2: {d.packets[0]?.CO2}ppm</div>}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        ))}
                        {devices.length === 0 && <div style={{ marginTop: 10, fontSize: '1.5rem', color: '#5d87ff' }}>할당된 장치가 없습니다.</div>}
                    </div>
                </CardBody>
            </Card>
            {viewTab === 'DETAIL' && (
            <>
                <div style={{ marginTop: 10, }} className='d-flex flex-row justify-content-around'>
                    {datas.map((data, i) => (
                        (deviceChartData.length > 0 && deviceChartData[0][data.type]) && (
                            <div key={i} className='d-flex' onClick={() => setSelectedIndex(i)} style={{ cursor: 'pointer' }}>
                                <Card stretch className={`custom-box-shadow rounded-2 d-flex`} style={{ border: '1px solid ' + (selectedIndex === i ? '#fa896b' : '#aaaaaa'), }}>
                                    <CardBody className={`rounded-2 align-items-center text-center`} style={{ backgroundColor: (selectedIndex === i ? '#fdede8' : '#ffffff'), color: (selectedIndex === i ? '#fa896b' : '#aaaaaa') }}>
                                        <CardLabel>
                                            <CardTitle style={{ fontSize: '1.5rem', marginTop: 10 }}>{data.label}</CardTitle>
                                            <CardSubTitle className='mt-3' style={{ fontSize: '1.0rem', marginTop: 20 }}>{deviceChartData.length > 0 ? deviceChartData[0][data.type] : ''}{data.unit}</CardSubTitle>
                                        </CardLabel>
                                    </CardBody>
                                </Card>
                            </div>)
                    ))}
                </div>
                <ButtonGroup>
                    <Button
                        type={'button'}
                        color={selectedTab === 1 ? 'info' : 'light'}
                        onClick={() => {
                            setSelectedTab(1);
                        }}>
                        {t('24시간')}
                    </Button>
                    <Button
                        type={'button'}
                        color={selectedTab === 7 ? 'info' : 'light'}
                        onClick={() => {
                            setSelectedTab(7);
                        }}>
                        {t('1주일')}
                    </Button>
                    <Button
                        type={'button'}
                        color={selectedTab === 30 ? 'info' : 'light'}
                        onClick={() => {
                            setSelectedTab(30);
                        }}>
                        {t('한달')}
                    </Button>
                </ButtonGroup>
                <ReactApexChart
                    height={500}
                    width={'100%'}
                    options={options}
                    series={options.series}
                    type={'line'}
                />
            </>)}
            <ArDeviceModal isOpen={isOpen} setIsOpen={setIsOpen} devices={devices} status={status} getDevice={getDevice} />
            {/* <BottomNavigation
                value={bottomIndex}
                onChange={(event, newValue) => setBottomIndex(newValue)}
                showLabels
                sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#4199f5' }}
            >
                <BottomNavigationAction label="Home" icon={<Home color={'#fff'} />} sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: 'Arial, sans-serif',
                        color: '#fff', 
                    }
                }} />
                <BottomNavigationAction label="장치목록" icon={<DeviceThermostat />} sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: 'Arial, sans-serif',
                        color: '#fff', 
                    }
                }} />
                <BottomNavigationAction label="Notifications" icon={<Icon name="Notifications" />} sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: 'Arial, sans-serif',
                        color: '#fff', 
                    }
                }} />
                <BottomNavigationAction label="Profile" icon={<Icon name="AccountCircle" />} sx={{
                    '& .MuiBottomNavigationAction-label': {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        fontFamily: 'Arial, sans-serif',
                        color: '#fff', 
                    }
                }} />
            </BottomNavigation> */}
        </div>}
        </>
    );
});

export default MobilePage;