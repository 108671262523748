import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import Button, { ButtonGroup } from "../../../components/bootstrap/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "../../../components/bootstrap/Modal";
import Card, { CardBody, CardHeader } from "../../../components/bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useMst } from "../../../models";
import Input from "../../../components/bootstrap/forms/Input";
import Icon from "../../../components/icon/Icon";
import Checks from "../../../components/bootstrap/forms/Checks";
import DeviceService from "../../../services/DeviceService";

const ArDeviceModal = observer(({ isOpen, setIsOpen, devices, status, getDevice }) => {
    const navigate = useNavigate();
    const { user } = useMst();

    const [checkAll, setCheckAll] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredDevices, setFilteredDevices] = useState(devices);

    const [, updateState] = useState(); // 상태 변수는 선언하지 않는다
    const forceUpdate = useCallback(() => updateState({}), []);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const updateDevices = useCallback(() => {
        console.log('updateDevices', devices);
        DeviceService.updateDevices({devices}).then((res) => {
            console.log('updateDevices', res);
            getDevice()
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices]);

    useEffect(() => {
        if (search === '') {
            setFilteredDevices(devices.filter(d => !d.favorite));
            return;
        }
        setFilteredDevices(devices.filter(d => !d.favorite).filter((device) => {
            return device.IMEI.includes(search) || device.SerialNumber.includes(search) || device.install.installName.includes(search);
        }));
    }, [devices, search]);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop={true}
            isScrollable={true}
            isCentered={true}
            //fullScreen={true}
            fullScreen={'md'}
            size={'md'}>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <div className={'text-dark'} style={{ fontSize: '1.5rem' }}>
                    <b>조회 단말기 추가</b>
                </div>
                <div>
                    <ButtonGroup>
                        <Button
                            type='button'
                            color='dark'
                            icon='Close'
                            onClick={() => {
                                setIsOpen(false);
                            }}>
                            {''}
                        </Button>
                    </ButtonGroup>
                </div>
            </ModalHeader>
            <ModalHeader className='border-top d-flex justify-content-between'>
                <Checks type='checkbox' style={{ width: 15, height: 15 }} checked={checkAll}
                    onClick={() => {
                        devices.forEach((device) => {
                            device.favorite = !checkAll;
                        });
                        setCheckAll(!checkAll);
                    }}
                 />
                <Input type='text' style={{ marginLeft: 10 }} placeholder='단말기 이름 / IMEI / Serial Number'
                    value={search} onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                 />
                <Icon icon='Search' size='3x' style={{ marginLeft: 10 }} />
            </ModalHeader>
            <ModalBody style={{ height: '50vh', padding: 5, overflowY: 'auto' }}>
                {filteredDevices.map((device, index) => (
                    <div key={`device-modal-${index}`} className='d-flex justify-content-left'
                        style={{ border: '1px solid #5d87ff', backgroundColor: '#ecf2ff', color: '#5d87ff', padding: 15,
                            margin: 10, borderRadius: 10, cursor: 'pointer', fontSize: '1.1rem',
                        }}
                        onClick={() => {
                            console.log('device clicked', device.favorite);
                        }}
                    >
                        <Checks type='checkbox' style={{ width: 15, height: 15 }} checked={device.favorite} onClick={() => {
                            device.favorite = !device.favorite;
                            forceUpdate();
                        }} />
                        <div style={{marginLeft: 10, width: '100%'}}>
                            <div className='d-flex justify-content-between w-100'>
                                <span>IMEI: {device.IMEI}</span>
                                <span>S/N: {device.SerialNumber}</span>
                            </div>
                            <span>{device.install.installName}</span>
                        </div>
                    </div>
                ))}
            </ModalBody>
            <ModalFooter>
                <Button
                    style={{ width: '100%' }}
                    type='button'
                    color='info'
                    onClick={() => {
                        updateDevices()
                        setIsOpen(false);
                    }}>
                    추가
                </Button>
            </ModalFooter>
        </Modal>
    );
});

export default ArDeviceModal;