import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import Card, { CardLabel, CardBody, CardTitle, CardHeader, CardActions } from '../../../components/bootstrap/Card';
import { useTranslation } from 'react-i18next'; //번역관련
import { useMst } from '../../../models';
import moment from 'moment';
import useRequest from '../../../hooks/useRequest';
import { useEffectOnce } from 'react-use';
import { AutoSizer, CellMeasurer, CellMeasurerCache, MultiGrid } from 'react-virtualized';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonGroup } from '../../../components/bootstrap/Button';
import Badge from '../../../components/bootstrap/Badge';
import showNotification from '../../../components/extras/showNotification';

const UserRestOrWorkListCard = ({ type = 'REST' }) => {
	const typeName = type === 'REST' ? '휴가' : '근무';
	const { t } = useTranslation(['translation', 'menu']);
	const ref = useRef();
	const navigate = useNavigate();
	const { company, user } = useMst();
	const { responseData: originData, loading, request } = useRequest([]);
	const [selectedDate, setSelectedDate] = useState({
		startDate: moment().startOf('year').format('YYYY-MM-DD'),
		endDate: moment().endOf('year').format('YYYY-MM-DD'),
	});
	const restColumnList = useMemo(
		() => [{ name: `${typeName} 유형` }, { name: '시작일' }, { name: '종료일' }, { name: '결재 상태' }],
		[typeName]
	);
	const cellCache = useMemo(
		() =>
			new CellMeasurerCache({
				defaultWidth: 120,
				fixedWidth: true,
			}),
		[]
	);

	useEffect(() => {
		request(
			CompanyService.getRestOrWorkStatus,
			[
				{
					companyId: company.id,
					userId: user.id,
					type: type,
					startDate: selectedDate.startDate,
					endDate: selectedDate.endDate,
				},
			],
			null,
			(error) => {
				showNotification(t(`${typeName} 현황`), t(`${typeName} 현황을 불러오는 도중에 문제가 발생했습니다.`), 'danger');
			},
			{
				key: 'startDate',
				type: 'date',
				direction: 'descending',
			}
		);
	}, [user, company, selectedDate, typeName, type, request, t]);

	const renderText = useCallback(
		({ columnIndex, rowIndex }) => {
			let data = '';
			const dataOfRowIndex = originData[rowIndex - 1];

			switch (columnIndex) {
				case 0:
					data = dataOfRowIndex?.restday?.name
						? dataOfRowIndex?.restday?.name === '근무' && dataOfRowIndex?.type === 'RECORD'
							? '근무 기록'
							: dataOfRowIndex?.restday?.name
						: '';
					break;
				case 1:
					data = dataOfRowIndex?.startDate ? moment(dataOfRowIndex?.startDate).format('L') : '-';
					break;
				case 2:
					data = dataOfRowIndex?.endDate ? moment(dataOfRowIndex?.endDate).format('L') : '-';
					break;
				case 3:
					const reqState = dataOfRowIndex?.state;
					data =
						reqState === 'REQUEST' ? (
							<div className='text-primary'>{t('진행중')}</div>
						) : reqState === 'SUCCESS' ? (
							<div className='text-success'>{t('완료')}</div>
						) : reqState === 'REJECT' ? (
							<div className='text-danger'>{t('반려')}</div>
						) : (
							'-'
						);
					break;
			}

			return data;
		},
		[originData, t]
	);

	const cellRenderer = useCallback(
		({ columnIndex, rowIndex, parent, style }) => (
			<CellMeasurer
				key={`row-${rowIndex}-${columnIndex}`}
				cache={cellCache}
				parent={parent}
				columnIndex={columnIndex}
				rowIndex={rowIndex}>
				{rowIndex <= 0 ? (
					<div
						style={style}
						className={`d-flex py-3 text-wrap ${
							columnIndex === 0 ? 'justify-content-center' : 'justify-content-center'
						}`}>
						{t(restColumnList[columnIndex].name)}
					</div>
				) : (
					<div
						style={style}
						className={`d-flex align-items-center p-2 text-wrap cursor-pointer justify-content-center ${
							rowIndex % 2 === 0 ? 'bg-l50-light' : ''
						}`}
						onClick={() => {
							// navigate(usersMenu.approvalDocument.path + '/' + originData[rowIndex - 1]?.id)
							navigate(eapproval.approvalReportAll.path, { state: { id: originData[rowIndex - 1]?.id } });
						}}
						>
						{renderText({ columnIndex, rowIndex })}
					</div>
				)}
			</CellMeasurer>
		),
		[cellCache, originData, restColumnList, navigate, renderText, t]
	);

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel>
					<CardTitle className='cursor-pointer' onClick={() => navigate('/' + eapproval.approvalReportAll.path)}>
						{t(`${typeName} 현황`)}
					</CardTitle>
				</CardLabel>
				<CardActions>
					<span className='text-wrap text-dark small'>{`${moment(selectedDate.startDate).format('L(ddd)')}~${moment(
						selectedDate.endDate
					).format('L(ddd)')}`}</span>
					<Badge color='light' className='text-wrap'>
						<span className='text-dark'>{t('총 상신 수')} : </span>
						<span className='fw-bold text-info fs-6'>{originData?.length || 0}</span>
					</Badge>
				</CardActions>
			</CardHeader>
			<CardBody
				className='dashboard-height overflow-auto'
				ref={ref}
				onMouseOver={(e) => {
					if (ref.current.scroll === true) {
						ref.current.scroll = false;
						document.body.style.overflow = 'hidden';
						document.body.style.paddingRight = '10px';
					}
				}}
				onMouseLeave={(e) => {
					ref.current.scroll = true;
					document.body.style.overflow = 'auto';
					document.body.style.removeProperty('padding-right');
				}}>
				{loading ? (
					<div className='h-100 d-flex flex-column justify-content-center align-items-center'>
						<p>{t('데이터를 불러오고 있습니다.')}</p>
					</div>
				) : originData.length <= 0 ? (
					<div className='text-center py-5 lead'>{t(`${typeName} 내역이 비어있습니다.`)}</div>
				) : (
					<AutoSizer>
						{({ width, height }) => {
							cellCache.clearAll();
							return (
								<MultiGrid
									classNameTopLeftGrid='border-light border-end border-bottom fw-bold'
									classNameTopRightGrid='border-light border-bottom fw-bold'
									classNameBottomLeftGrid='border-light border-end'
									cellRenderer={cellRenderer}
									columnCount={restColumnList.length}
									fixedColumnCount={0}
									fixedRowCount={1}
									rowCount={originData.length + 1}
									columnWidth={(width - 12) / restColumnList.length}
									rowHeight={cellCache.rowHeight}
									height={height}
									width={width}
								/>
							);
						}}
					</AutoSizer>
				)}
			</CardBody>
		</Card>
	);
};
export default React.memo(UserRestOrWorkListCard);
