import React from 'react';
import Page from '../../../layout/Page/Page';
import { observer } from 'mobx-react';
import MySimpleProfile from './MySimpleProfile';
import UserWeeklyAttendHistoryCard from './UserWeeklyAttendHistoryCard';
import NoticeBoard from './NoticeBoard';
import UserApprovalNeedCard from './UserApprovalNeedCard';
import SubHeader, { SubHeaderLeft } from '../../../layout/SubHeader/SubHeader';
import Breadcrumb, { BreadcrumbItem } from '../../../components/bootstrap/Breadcrumb';
import { useTranslation } from 'react-i18next';
import UserRestOrWorkListCard from './UserRestOrWorkListCard';

const UserDashboard = observer(() => {
	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			<SubHeader>
				<SubHeaderLeft>
					<Breadcrumb list={null} tag={'nav'}>
						<BreadcrumbItem tag='nav' to={demoPages.sales.subMenu.dashboard.path}>
							{t(demoPages.sales.subMenu.dashboard.text)}
						</BreadcrumbItem>
					</Breadcrumb>
				</SubHeaderLeft>
			</SubHeader>

			<Page container='fluid' className='pe-2'>
				<div className='row'>
					<div className='col-12 col-md-4 col-xxl-2'>
						<MySimpleProfile />
					</div>
					<div className='col-12 col-md-8 col-xxl-3'>
						<NoticeBoard />
					</div>
					{/* 권한 작업 중이므로 잠시 제외 */}
					{/* <div className='col-xl-3'>
						<AttendMyTeam />
					</div> */}
					<div className='col-12 col-xxl-7'>
						<UserWeeklyAttendHistoryCard />
					</div>
					<div className='col-12 col-xl-4'>
						<UserRestOrWorkListCard type='REST' />
					</div>
					<div className='col-12 col-xl-4'>
						<UserRestOrWorkListCard type='WORK' />
					</div>
					<div className='col-12 col-xl-4'>
						<UserApprovalNeedCard />
					</div>
				</div>
			</Page>
		</>
	);
});

export default UserDashboard;
